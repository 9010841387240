import { styled } from "@mui/material";
import { decode } from "blurhash";
import { createCanvas } from "canvas";
import { IImage } from "models/shared";
import NextImage, { ImageProps } from "next/image";
import { useMemo } from "react";

export const decodeBlurhash = (blurhash?: string) => {
  if (!blurhash) return;

  const width = 32;
  const height = 32;

  const pixels = decode(blurhash, width, height);

  const canvas = createCanvas(width, height);
  const context = canvas.getContext("2d");
  const imageData = context.createImageData(width, height);
  imageData.data.set(pixels);
  context.putImageData(imageData, 0, 0);

  const dataUrl = canvas.toDataURL();

  return dataUrl;
};

const hasHostname = (src: string) => {
  try {
    return !!new URL(src).hostname;
  } catch (e) {
    return false;
  }
};

const StyledImage = styled(NextImage)(() => ({
  objectFit: "cover",
}));

interface IProps
  extends Omit<ImageProps, "src" | "alt">,
    Pick<IImage, "url" | "alternativeText" | "blurhash"> {
  src?: ImageProps["src"];
  alt?: ImageProps["alt"];
}

const Image = ({
  url,
  src,
  alternativeText,
  width,
  height,
  fill,
  blurDataURL,
  blurhash,
  ...props
}: IProps) => {
  blurDataURL = useMemo(() => {
    if (fill || (width && height && +width > 40 && +height > 40)) {
      return decodeBlurhash(blurhash);
    }
  }, [blurhash, fill, width, height]);

  const urlOrSrc = url || src;
  if (!urlOrSrc) return null;

  const withHostname =
    typeof urlOrSrc !== "string" ||
    (typeof urlOrSrc === "string" &&
      (urlOrSrc.startsWith("/assets") ||
        urlOrSrc.startsWith("blob:") ||
        urlOrSrc.startsWith("data:") ||
        hasHostname(urlOrSrc)));

  const imageSrc = withHostname ? urlOrSrc : (process.env.NEXT_PUBLIC_BACKEND_URL ?? "") + urlOrSrc;

  return (
    <StyledImage
      placeholder={blurDataURL ? "blur" : "empty"}
      {...props}
      src={imageSrc}
      alt={alternativeText ?? ""}
      width={fill ? undefined : width}
      height={fill ? undefined : height}
      fill={fill}
      blurDataURL={blurDataURL}
    />
  );
};

export default Image;
