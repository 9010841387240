import { combineReducers } from "@reduxjs/toolkit";
import auth from "components/auth/store";
import dashboard from "components/dashboard/store";
import main from "components/main/store";
import shared from "components/shared/store";

export const combinedReducer = combineReducers({
  shared,
  main,
  auth,
  dashboard,
});
