import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IForProfessionalsContent } from "models/main/for-professionals";
import { heroSectionPopulateKeys } from "models/main/shared";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getForProfessionalsContentReq = () => {
  return axiosInstance
    .get(`/api/for-professional`, {
      params: {
        fields: ["id"],
        populate: {
          hero: heroSectionPopulateKeys,
          whyJoinSection: {
            populate: {
              items: { populate: { icon: imagePopulate } },
              button: true,
            },
          },
          howToJoinSection: { populate: { image: imagePopulate, button: true } },
          helpSection: { populate: "*" },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IForProfessionalsContent>)
    .catch(getError);
};
