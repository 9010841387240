import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IHomeContent } from "models/main/home";
import { heroSectionPopulateKeys } from "models/main/shared";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getHomeContentReq = () => {
  return axiosInstance
    .get(`/api/home`, {
      params: {
        fields: ["id"],
        populate: {
          hero: heroSectionPopulateKeys,
          howItWorksSection: {
            populate: {
              items: { populate: { icon: imagePopulate } },
              button: true,
            },
          },
          findYourExpertSection: {
            populate: {
              items: { populate: { image: imagePopulate } },
              buttons: true,
            },
          },
          whyUsSection: { populate: { image: imagePopulate } },
          checklistSection: { populate: { items: { populate: "*" } } },
          helpSection: { populate: "*" },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IHomeContent>)
    .catch(getError);
};
