import { ButtonProps } from "@mui/material";
import {
  Components,
  CssVarsPalette,
  Palette,
  TypographyVariantsOptions,
} from "@mui/material/styles";
import { EntityState } from "@reduxjs/toolkit";
import { ErrorResult } from "lib/axios/error";

//#region Files
export interface IFile {
  id?: string;
  url: string;
  mime?: string;
}

export function isFile(arg: any): arg is IFile {
  return arg && !arg.mine?.startsWith("image/");
}

export const imagePopulate = {
  fields: ["id", "url", "mime", "alternativeText", "caption", "width", "height", "blurhash"],
};

export const imagePopulateWithFormats = {
  fields: [...imagePopulate.fields, "formats"],
};

type ImageFormat = Pick<IImage, "url" | "width" | "height">;

export interface IImage extends IFile {
  alternativeText: string;
  caption?: string;
  width?: number;
  height?: number;
  blurhash?: string;
  formats?: {
    large: ImageFormat;
    medium: ImageFormat;
    small: ImageFormat;
    thumbnail: ImageFormat;
  };
}

export function isImage(arg: any): arg is IImage {
  return arg && arg.mine?.startsWith("image/");
}
//#endregion

//#region SEO
export interface ISeo {
  /**
   * Should the page be indexed?
   *
   * Initial value: `false`
   */
  indexing?: boolean;
  /**
   * Meta Title
   *
   * Initial value: `page_url`
   */
  metaTitle: string;
  /**
   * Meta Description
   *
   * Initial value: `undefined`
   */
  metaDescription?: string;
  /**
   * Open Graph image
   *
   * Initial value: `undefined`
   */
  metaImage?: IImage;
  /**
   * Meta Url
   *
   * Initial value: `page_url`
   */
  metaUrl?: string;
}

export interface IDefaultSeo {
  siteName: string;
  url: string;
  defaultMetaImage: ISeo["metaImage"];
  twitter?: {
    handle: string;
    site: string;
  };
}
//#endregion

//#region Layout
export interface ISharedLayout {
  logos: { light: IImage; dark: IImage };
  theme: {
    palette: Partial<Palette & CssVarsPalette>;
  };
  typography?: TypographyVariantsOptions;
  button?: NonNullable<Components["MuiButton"]>["styleOverrides"];
  settings: {
    enablePageTransition: boolean;
  };
}
//#endregion

//#region
export interface ILinkButton {
  text: string;
  path: string;
  variant?: ButtonProps["variant"];
}
//#endregion

//#region Entity List
export type EntityListParams = {
  search: string | null;
  pagination: {
    start: number;
    limit: number;
    total?: number;
  };
};

export type PartialEntityListParams = Partial<
  Omit<EntityListParams, "pagination"> & {
    pagination: Partial<Omit<EntityListParams["pagination"], "total">>;
  }
>;

export type RequestMeta = {
  meta: {
    requestStatus: "initial" | "pending" | "fulfilled" | "rejected";
    error?: ErrorResult["error"];
  };
};

export type EntityListState<T> = EntityState<T, string> & RequestMeta & EntityListParams;
//#endregion
