import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IArticle, IArticleContent, IRelatedArticle } from "models/main/blog";
import { imagePopulate, imagePopulateWithFormats } from "models/shared";
import { transformResponse } from "utils/format";

export const getArticleContentReq = () => {
  return axiosInstance
    .get(`/api/blog-article`, {
      params: {
        populate: {
          relatedArticlesSection: { populate: "*" },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IArticleContent>)
    .catch(getError);
};

export const getPathExistsReq = (slug: string) => {
  return axiosInstance
    .get(`/api/articles`, {
      params: {
        publicationState: "preview",
        fields: ["id", "slug"],
        filters: { slug },
      },
    })
    .then(({ data }) => data)
    .then(({ data }) => data[0])
    .then(transformResponse<{ id: string; slug: string }>)
    .catch(getError);
};

export const getPathsReq = () => {
  return axiosInstance
    .get(`/api/articles`, {
      params: {
        fields: ["id", "slug"],
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<{ slug: string }[]>)
    .catch(getError);
};

export const getMaxPathsReq = (start: number) => {
  return axiosInstance
    .get(`/api/articles`, {
      params: {
        fields: ["id", "slug"],
        pagination: { start, limit: -1 },
      },
    })
    .then(({ data }) => data)
    .then(({ data, meta }) => ({ data: transformResponse<{ slug: string }[]>(data), meta }))
    .catch(getError);
};

export const getArticleReq = (slug: string, publicationState?: "preview" | "live") => {
  return axiosInstance
    .get(`/api/articles`, {
      params: {
        publicationState,
        filters: { slug },
        populate: {
          categories: true,
          featuredImage: imagePopulateWithFormats,
          blocks: {
            on: {
              "blog.text-block": true,
              "blog.images-block": { populate: { images: imagePopulate } },
            },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(({ data }) => data[0])
    .then(transformResponse<IArticle>)
    .catch(getError);
};

export const getRelatedArticlesReq = (slug: string, categories: string[], locations: string[]) => {
  return axiosInstance
    .get(`/api/articles`, {
      params: {
        filters: {
          $and: [
            { slug: { $ne: slug } },
            { $or: locations.map((l) => ({ locations: { $containsi: l } })) },
            { $or: categories.map((c) => ({ categories: { name: c } })) },
          ],
        },
        sort: "publishedAt:desc",
        pagination: { limit: 3 },
        populate: {
          featuredImage: imagePopulate,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IRelatedArticle[]>)
    .catch(getError);
};
