import axios from "axios";

export interface ErrorResult {
  error: { message: string; data?: any };
}

export const isError = (result: any): result is ErrorResult => {
  return (
    result &&
    typeof result === "object" &&
    "error" in result &&
    typeof result.error.message === "string"
  );
};

type RestError = {
  status: string;
  name: string;
  message: string;
  details: {
    errors: { message: string; name: string; path: string[] }[];
  };
};

export const getError = (error: any): ErrorResult => {
  if (axios.isAxiosError(error) && error.response) {
    const restError: RestError = error.response.data.error;
    const message = restError?.message || error.response.data;
    return { error: { message, data: restError } };
  }

  return { error: { message: error.message } };
};

export const throwIfError = (errors: unknown[]) => {
  const error = (errors.find((error) => isError(error)) as ErrorResult)?.error;

  if (error) {
    throw new Error(`Failed to fetch data: ${error.message}`);
  }
};
