import { Box, Typography, TypographyProps } from "@mui/material";
import { sanitize } from "lib/dompurify";
import { useEffect, useRef } from "react";

type IProps = Omit<TypographyProps, "component"> & {
  openAllLinksInNew?: boolean;
  children: string | undefined;
};

const InnerHTML = ({ children, variant, openAllLinksInNew, ...props }: IProps) => {
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openAllLinksInNew && parentRef.current) {
      const links = parentRef.current.getElementsByTagName("a");
      for (let i = 0; i < links.length; i++) {
        links[i].target = "_blank";
        links[i].rel = "noopener noreferrer";
      }
    }
  }, [openAllLinksInNew]);

  return (
    <Typography
      ref={parentRef}
      component="div"
      variant={variant}
      sx={{
        a: { color: "var(--palette-primary-main)" },
        "ol, ul": { listStyle: "revert", marginTop: "revert", paddingLeft: "revert" },
      }}
    >
      <Box
        {...props}
        dangerouslySetInnerHTML={children ? { __html: sanitize(children) } : undefined}
      />
    </Typography>
  );
};

export default InnerHTML;
