import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IProfessionalRegisterContent } from "models/auth";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getProfessionalRegisterContentReq = () => {
  return axiosInstance
    .get(`/api/professional-register`, {
      params: {
        populate: {
          steps: {
            populate: {
              forms: formPopulate["form"],
              nextStepGroups: { populate: "*" },
            },
          },
          successSection: {
            populate: { icon: imagePopulate, button: true },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IProfessionalRegisterContent>)
    .then((data) => {
      for (let s of data.steps) {
        for (let form of s.forms) {
          transformForm({ form }, ["form"]);
        }
      }
      return data;
    })
    .catch(getError);
};
