import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IFAQContent } from "models/main/faq";
import { HYDRATE } from "next-redux-wrapper";
import { getProfessionalFAQContentReq } from "rest-api/main/professional-faq";

export const getProfessionalFAQContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/professionalFaq/sections/getProfessionalFAQContent", async (_, { rejectWithValue }) => {
  const result = await getProfessionalFAQContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectMainProfessionalFAQContent = ({ main }: RootState) =>
  main.professionalFaq.content;
export const selectMainProfessionalFAQContentHero = ({ main }: RootState) =>
  main.professionalFaq.content.hero;

const initialState: IFAQContent = {
  hero: {
    title: "",
    titleRotateWords: [],
    subtitle: "",
    content: "",
    button: {
      text: "",
      path: "",
    },
  },
  faqSections: [],
};

const contentSlice = createSlice({
  name: "main/professionalFaq/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalFAQContent.fulfilled, (_, action) => action.payload)
      .addCase(HYDRATE, (_, action: PayloadAction<IFAQContent, typeof HYDRATE>) => action.payload);
  },
});

export default contentSlice.reducer;
