import { combineReducers } from "@reduxjs/toolkit";
import content from "./contentSlice";
import requests from "./requestsSlice";

const reducer = combineReducers({
  content,
  requests,
});

export default reducer;
