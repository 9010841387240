import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { formPopulate } from "models/form";
import { IBuyerChecklistContent } from "models/main/buyer-checklist";
import { heroSectionPopulateKeys } from "models/main/shared";
import { transformForm, transformResponse } from "utils/format";

export const getBuyerChecklistContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-checklist`, {
      params: {
        fields: ["id"],
        populate: {
          hero: heroSectionPopulateKeys,
          checklistSection: { populate: formPopulate },
          helpSection: { populate: "*" },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IBuyerChecklistContent>)
    .then((data) => transformForm(data, ["checklistSection.form"]))
    .catch(getError);
};
