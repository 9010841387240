import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IForSellingContent } from "models/dashboard/consumer/for-selling";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerForSellingContentReq } from "rest-api/dashboard/consumer/for-selling";

export const getConsumerForSellingContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/forSelling/content/getConsumerForSellingContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerForSellingContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerForSellingContent = ({ dashboard }: RootState) =>
  dashboard.consumer.forSelling.content;

const initialState: IForSellingContent = {
  form: { fields: [], submitButtonText: "" },
};

const contentSlice = createSlice({
  name: "dashboard/consumer/forSelling/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerForSellingContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IForSellingContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
