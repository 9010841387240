import { combineReducers } from "@reduxjs/toolkit";
import edit from "../edit/store";
import content from "./contentSlice";

const reducer = combineReducers({
  content,
  edit,
});

export default reducer;
