import { combineReducers } from "@reduxjs/toolkit";
import layout from "../layout/store";
import profile from "../profile/store";
import leadBoard from "../referrals-board/store";

const reducer = combineReducers({
  layout,
  profile,
  leadBoard,
});

export default reducer;
