import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IRequestAnotherExpertEditContent } from "models/dashboard/consumer/find-experts";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerRequestAnotherExpertEditContentReq } from "rest-api/dashboard/consumer/request-another";
import { getLocationsFieldReq } from "rest-api/fields";

export const getConsumerRequestAnotherExpertEditContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/findExperts/requestAnotherExpert/edit/content/getConsumerRequestAnotherExpertEditContent",
  async (_, { rejectWithValue }) => {
    const requestAnotherExpertEditContentResult =
      await getConsumerRequestAnotherExpertEditContentReq();

    if (isError(requestAnotherExpertEditContentResult)) {
      return rejectWithValue(requestAnotherExpertEditContentResult);
    }

    const locationsFieldResult = await getLocationsFieldReq();

    if (isError(locationsFieldResult)) {
      return rejectWithValue(locationsFieldResult);
    }

    requestAnotherExpertEditContentResult.steps.forEach((s) => {
      if (s.type !== "ComponentConsumerRequestAnotherExpertStepQuestions") return;

      s.forms.forEach((form) => {
        const locationField = {
          ...locationsFieldResult,
          name: `expertQuestions.${form.name}_locations`,
        };
        form.fields = [locationField, ...form.fields];
      });
    });

    return requestAnotherExpertEditContentResult;
  },
);

export const selectConsumerRequestAnotherExpertEditContent = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.requestAnotherExpert.edit.content;

const initialState: IRequestAnotherExpertEditContent = {
  steps: [],
};

const contentSlice = createSlice({
  name: "dashboard/consumer/findExperts/requestAnotherExpert/edit/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerRequestAnotherExpertEditContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IRequestAnotherExpertEditContent, typeof HYDRATE>) =>
          action.payload,
      );
  },
});

export default contentSlice.reducer;
