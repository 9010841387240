import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IDefaultSeo, imagePopulate, imagePopulateWithFormats, ISeo } from "models/shared";
import { transformResponse } from "utils/format";

export const getDefaultSeoReq = () => {
  return axiosInstance
    .get(`api/config`, {
      params: {
        populate: { defaultSeo: { populate: { defaultMetaImage: imagePopulate } } },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<{ defaultSeo: IDefaultSeo }>)
    .then(({ defaultSeo }) => defaultSeo)
    .catch(getError);
};

export const getSEOReq = (path: string) => {
  return axiosInstance
    .get(`/api${path}`, {
      params: {
        fields: ["id"],
        populate: {
          seo: {
            populate: {
              metaImage: imagePopulateWithFormats,
            },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<{ seo: ISeo }>)
    .then(({ seo }) => seo)
    .catch(getError);
};

export const getBlogSEOReq = (slug: string) => {
  return axiosInstance
    .get(`/api/articles`, {
      params: {
        publicationState: "preview",
        filters: { slug },
        fields: ["id", "slug"],
        populate: {
          seo: {
            populate: {
              metaImage: imagePopulateWithFormats,
            },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(({ data }) => data[0])
    .then(transformResponse<{ slug: string; seo: ISeo } | undefined>)
    .then((data) => (data ? { ...data.seo, metaUrl: `/blog/${data.slug}` } : ({} as ISeo)))
    .catch(getError);
};
