import { useEffect, useState } from "react";

const useStorageChange = () => {
  const [key, setKey] = useState<string | null>(null);
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    const storageChanged = (ev: StorageEvent) => {
      setKey(ev.key);
      setValue(ev.newValue);
    };

    window.addEventListener("storage", storageChanged);
    return () => window.removeEventListener("storage", storageChanged);
  }, []);

  return { key, value };
};

export default useStorageChange;
