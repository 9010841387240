import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IBlogContent } from "models/main/blog";
import { HYDRATE } from "next-redux-wrapper";
import { getBlogContentReq, getPopulatedCategoriesReq } from "rest-api/main/blog";

export const getBlogContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/blog/content/getBlogContent", async (_, { rejectWithValue }) => {
  const result = await getBlogContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  const populatedCategoriesResult = await getPopulatedCategoriesReq();

  if (isError(populatedCategoriesResult)) {
    return rejectWithValue(populatedCategoriesResult);
  }

  result.filteredArticlesSection.categories = result.filteredArticlesSection.categories.filter(
    (c) => populatedCategoriesResult.includes(c.name),
  );

  return result;
});

export const selectBlogContent = ({ main }: RootState) => main.blog.content;

const initialState: IBlogContent = {
  titlePart1: "",
  titlePart2: "",
  subtitle: "",
  featuredArticle: {
    id: "",
    title: "",
    slug: "",
    excerpt: "",
    duration: 0,
    featuredImage: { url: "", alternativeText: "" },
    createdAt: "",
    publishedAt: "",
  },
  newsSection: {
    title: "",
    subtitle: "",
  },
  filteredArticlesSection: {
    title: "",
    locations: [],
    categories: [],
  },
  serviceRequestedSection: {
    title: "",
    content: "",
    buttonText: "",
    icon: { url: "", alternativeText: "" },
    article: {
      title: "",
      slug: "",
    },
  },
};

const contentSlice = createSlice({
  name: "main/blog/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogContent.fulfilled, (_, action) => action.payload)
      .addCase(HYDRATE, (_, action: PayloadAction<IBlogContent, typeof HYDRATE>) => action.payload);
  },
});

export default contentSlice.reducer;
