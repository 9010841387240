import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IBuyerSellerLoginContent } from "models/auth";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getConsumerLoginContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-login`, {
      params: {
        populate: {
          ...formPopulate,
          socialLogins: { populate: { icon: imagePopulate } },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IBuyerSellerLoginContent>)
    .then((data) => transformForm(data, ["form"]))
    .catch(getError);
};
