import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IYourExpertsContent } from "models/dashboard/consumer/your-experts";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerYourExpertsContentReq } from "rest-api/dashboard/consumer/your-experts";

export const getConsumerYourExpertsContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/ypb/content/getConsumerYourExpertsContentReq",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerYourExpertsContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerYPBContent = ({ dashboard }: RootState) =>
  dashboard.consumer.ypb.content;

const initialState: IYourExpertsContent = {
  title: "",
  pendingRequest: {
    title: "",
    content: "",
    icon: { url: "", alternativeText: "" },
  },
  moreExpertsForm: {
    field: {
      name: "",
      label: "",
      options: [],
    },
    submitButtonText: "",
  },
};

const contentSlice = createSlice({
  name: "dashboard/consumer/ypb/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerYourExpertsContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IYourExpertsContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
