import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { ISharedLayout } from "models/shared";
import { HYDRATE } from "next-redux-wrapper";
import { getSharedLayoutReq } from "rest-api/global-layout-settings";

export const getSharedLayout = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("shared/layout/content/getSharedLayout", async (_, { rejectWithValue }) => {
  const result = await getSharedLayoutReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectSharedLayoutContent = ({ shared }: RootState) => shared.layout.content;

const initialState: ISharedLayout = {
  logos: {
    light: { url: "", alternativeText: "" },
    dark: { url: "", alternativeText: "" },
  },
  theme: {
    palette: {
      primary: {
        light: "#BBB896",
        lightChannel: "187 184 150",
        main: "#77722E",
        mainChannel: "119 114 46",
        dark: "#4D4B2F",
        darkChannel: "77 75 47",
        contrastText: "#FFFFFF",
        contrastTextChannel: "255 255 255",
      },
      secondary: {
        light: "#F7C033",
        lightChannel: "247 192 51",
        main: "#F6B100",
        mainChannel: "246 177 0",
        dark: "#AC7B00",
        darkChannel: "172 123 0",
        contrastText: "#000000",
        contrastTextChannel: "0 0 0",
      },
      background: {
        main: "#FFFFFF",
        mainChannel: "255 255 255",
        default: "#F8F8F5",
        defaultChannel: "248 248 245",
        paper: "#EEEEEA",
        paperChannel: "238 238 234",
      },
      text: {
        primary: "#02010D",
        primaryChannel: "2 1 13",
        secondary: "#FFFFFF",
        secondaryChannel: "255 255 255",
        disabled: "#DEDDCD",
      },
    },
  },
  settings: { enablePageTransition: false },
};

const layoutContentSlice = createSlice({
  name: "shared/layout/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSharedLayout.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<typeof initialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export default layoutContentSlice.reducer;
