import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectAuthConsumerRegisterState = ({ auth }: RootState) =>
  auth.register.consumer.state;
export const selectAuthConsumerRegisterActiveStep = createSelector(
  (state: RootState) => state.auth.register.consumer,
  ({ content: { questionnaireSteps }, state: { activeStepId } }) =>
    questionnaireSteps.find((s) => s.stepId === activeStepId) ?? questionnaireSteps[0],
);
export const selectAuthConsumerRegisterActiveSteps = createSelector(
  (state: RootState) => state.auth.register.consumer,
  ({ content: { questionnaireSteps }, state: { activeStepId, prevStepIds } }) =>
    questionnaireSteps.filter(
      (s) => !prevStepIds.length || prevStepIds.includes(s.stepId) || s.stepId === activeStepId,
    ),
);

export const selectQuestionnaireFormValues = ({ auth }: RootState) =>
  auth.register.consumer.state.questionnaireFormValues;

export const selectSocialRegisterFormValues = ({ auth }: RootState) =>
  auth.register.consumer.state.socialRegisterFormValues;

interface IInitialState {
  activeStepId: string;
  prevStepIds: string[];
  questionnaireFormValues?: Record<string, any>;
  registerEmail?: string;
  socialRegisterFormValues?: Record<string, any>;
  activeSection:
    | "questionnaire"
    | "register"
    | "emailRegister"
    | "emailVerify"
    | "emailVerifyResend"
    | "google"
    | "apple";
}

const initialState: IInitialState = {
  activeStepId: "",
  prevStepIds: [],
  activeSection: "questionnaire",
};

const registerStateSlice = createSlice({
  name: "auth/register/consumer/state",
  initialState,
  reducers: {
    setActiveStepId: (state, { payload }: PayloadAction<IInitialState["activeStepId"]>) => {
      if (payload < state.activeStepId) {
        const index = state.prevStepIds.indexOf(payload);
        state.prevStepIds = index !== -1 ? state.prevStepIds.slice(0, index) : [];
      }
      state.activeStepId = payload;
    },
    addToPrevStepIds: (state, action: PayloadAction<IInitialState["prevStepIds"]>) => {
      state.prevStepIds.push(...action.payload);
    },
    setQuestionnaireFormValues: (
      state,
      action: PayloadAction<IInitialState["questionnaireFormValues"]>,
    ) => {
      state.questionnaireFormValues = action.payload;
    },
    setRegisterEmail: (state, action: PayloadAction<IInitialState["registerEmail"]>) => {
      state.registerEmail = action.payload;
    },
    setSocialRegisterFormValues: (
      state,
      action: PayloadAction<IInitialState["socialRegisterFormValues"]>,
    ) => {
      state.socialRegisterFormValues = action.payload;
    },
    setActiveSection: (state, action: PayloadAction<IInitialState["activeSection"]>) => {
      state.activeSection = action.payload;
    },
  },
});

export const {
  setActiveStepId,
  addToPrevStepIds,
  setQuestionnaireFormValues,
  setRegisterEmail,
  setSocialRegisterFormValues,
  setActiveSection,
} = registerStateSlice.actions;

export default registerStateSlice.reducer;
