import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { ITermsAndConditionsContent } from "models/main/terms-and-conditions";
import { HYDRATE } from "next-redux-wrapper";
import { getTermsAndConditionsContentReq } from "rest-api/main/terms-and-conditions";

export const getTermsAndConditionsContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "main/termsAndConditions/sections/getTermsAndConditionsContent",
  async (_, { rejectWithValue }) => {
    const result = await getTermsAndConditionsContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectMainTermsAndConditionsContent = ({ main }: RootState) =>
  main.termsAndConditions.content;
export const selectMainTermsAndConditionsContentHero = ({ main }: RootState) =>
  main.termsAndConditions.content.hero;

const initialState: ITermsAndConditionsContent = {
  hero: {
    title: "",
    titleRotateWords: [],
    subtitle: "",
    content: "",
    button: {
      text: "",
      path: "",
    },
  },
  content: "",
};

const contentSlice = createSlice({
  name: "main/termsAndConditions/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTermsAndConditionsContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<ITermsAndConditionsContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
