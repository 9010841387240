import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSharedLayout } from "components/shared/layout/store/layoutContentSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { AppDispatch, RootState } from "lib/redux";
import merge from "lodash/merge";
import { ProfessionalDashboardLayout } from "models/dashboard/professional/layout";
import { HYDRATE } from "next-redux-wrapper";
import {
  getProfessionalDashboardLayoutReq,
  getProfessionalDashboardMenusReq,
} from "rest-api/dashboard/professional/layout-settings";

export const getProfessionalDashboardLayout = createAsyncThunk<
  typeof initialState,
  void,
  { dispatch: AppDispatch; rejectWithValue: ErrorResult }
>(
  "dashboard/professional/layout/content/getProfessionalDashboardLayout",
  async (_, { dispatch, rejectWithValue }) => {
    await dispatch(getSharedLayout());
    const menuData = await getProfessionalDashboardMenusReq();

    if (isError(menuData)) {
      return rejectWithValue(menuData);
    }

    const contentData = await getProfessionalDashboardLayoutReq();

    if (isError(contentData)) {
      return rejectWithValue(contentData);
    }

    return merge(menuData, contentData);
  },
);

export const selectProfessionalDashboardLayoutContent = ({ dashboard }: RootState) =>
  dashboard.professional.layout.content;

const initialState: ProfessionalDashboardLayout = {
  nav: { items: [] },
  mobileNav: { items: [] },
  userMenu: { items: [] },
};

const layoutContentSlice = createSlice({
  name: "dashboard/professional/layout/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalDashboardLayout.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<typeof initialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export default layoutContentSlice.reducer;
