import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import pick from "lodash/pick";
import uniqBy from "lodash/uniqBy";
import {
  FormField,
  FormFieldOptions,
  IAutoComplete,
  ICheckboxGroup,
  IRadioGroup,
  formPopulate,
} from "models/form";
import { transformForm, transformResponse } from "utils/format";

const transformFieldOptions = (fields: FormField[]) => {
  const fieldOptions = fields
    .map((f) => ({
      ...pick(f, "id", "name"),
      options:
        f.type === "ComponentFormAutoComplete"
          ? (f as IAutoComplete).autoCompleteOptions
          : f.type === "ComponentFormCheckboxGroup"
            ? (f as ICheckboxGroup).checkboxGroupOptions
            : f.type === "ComponentFormRadioGroup" && (f as IRadioGroup).radioGroupOptions,
    }))
    .filter((f): f is FormFieldOptions => !!f.options);

  return uniqBy(fieldOptions, "name");
};

export async function getLocationsFieldReq(): Promise<FormField | ErrorResult> {
  return axiosInstance
    .get<{ data: { attributes: FormField }[] }>("/api/form-fields", {
      params: {
        filters: { field: { name: "expertQuestions.locations" } },
        populate: formPopulate["form"]["populate"]["fields"]["populate"],
      },
    })
    .then(({ data }) => data)
    .then(({ data }) => transformResponse<FormField[]>(data))
    .then((data) => transformForm({ form: { fields: data } }, ["form"]).form.fields[0])
    .catch(getError);
}

export async function getFieldOptionsReq(): Promise<FormFieldOptions[] | ErrorResult> {
  return axiosInstance
    .get<{ data: { attributes: FormField }[] }>("/api/form-fields", {
      params: {
        filters: { $or: [{ type: "Auth" }, { type: "Expert Questions" }] },
        populate: formPopulate["form"]["populate"]["fields"]["populate"],
        pagination: { limit: -1 },
      },
    })
    .then(({ data }) => data)
    .then(({ data }) => transformResponse<FormField[]>(data))
    .then((data) => transformForm({ form: { fields: data } }, ["form"]).form.fields)
    .then(transformFieldOptions)
    .catch(getError);
}
