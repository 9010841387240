import { combineReducers } from "@reduxjs/toolkit";
import consumer from "../consumer/store";
import layout from "../layout/store";
import professional from "../professional/store";
import fieldOptions from "./fieldOptionsSlice";
import profile from "./profileSlice";

const reducer = combineReducers({
  professional,
  consumer,
  profile,
  layout,
  fieldOptions,
});

export default reducer;
