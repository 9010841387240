import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IRequestAnotherExpertContent } from "models/dashboard/consumer/find-experts";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerRequestAnotherExpertContentReq } from "rest-api/dashboard/consumer/request-another";

export const getConsumerRequestAnotherExpertContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/findExperts/requestAnotherExpert/content/getConsumerRequestAnotherExpertContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerRequestAnotherExpertContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerRequestAnotherExpertContent = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.requestAnotherExpert.content;

const initialState: IRequestAnotherExpertContent = {
  title: "",
  infoSection: { title: "", items: [] },
  actions: [],
};

const contentSlice = createSlice({
  name: "dashboard/consumer/findExperts/requestAnotherExpert/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerRequestAnotherExpertContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IRequestAnotherExpertContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
