import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IDeleteAccountContent } from "models/auth";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getDeleteAccountContentReq = () => {
  return axiosInstance
    .get(`/api/delete-account`, {
      params: {
        populate: {
          header: {
            populate: { icon: imagePopulate },
          },
          ...formPopulate,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IDeleteAccountContent>)
    .then((data) => transformForm(data, ["form"]))
    .catch(getError);
};
