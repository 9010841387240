import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSharedLayout } from "components/shared/layout/store/layoutContentSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { AppDispatch, RootState } from "lib/redux";
import merge from "lodash/merge";
import { INewsletterSection, MainLayout } from "models/main/shared";
import { HYDRATE } from "next-redux-wrapper";
import { getMainLayoutReq, getMainMenusReq } from "rest-api/main/main-layout-settings";

export const getMainLayout = createAsyncThunk<
  typeof initialState,
  void,
  { dispatch: AppDispatch; rejectValue: ErrorResult }
>("main/layout/content/getMainLayout", async (_, { dispatch, rejectWithValue }) => {
  await dispatch(getSharedLayout());
  const menuData = await getMainMenusReq();

  if (isError(menuData)) {
    return rejectWithValue(menuData);
  }

  const contentData = await getMainLayoutReq();

  if (isError(contentData)) {
    return rejectWithValue(contentData);
  }

  return merge(menuData, contentData);
});

export const selectMainLayoutContent = ({ main }: RootState) => main.layout.content;

const initialState: MainLayout = {
  mainNav: { items: [] },
  mainNavDrawer: { items: [] },
  newsletter: {
    title: "",
    subtitle: "",
    form: {
      fields: [],
      submitButtonText: "",
    },
    variant: "dark",
  },
  footer: {
    content: "",
    nav: { items: [] },
    disclaimer: "",
  },
  socials: [],
};

const layoutContentSlice = createSlice({
  name: "main/layout/content",
  initialState,
  reducers: {
    setNewsletterVariant: (state, action: PayloadAction<INewsletterSection["variant"]>) => {
      state.newsletter.variant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainLayout.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<typeof initialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export const { setNewsletterVariant } = layoutContentSlice.actions;

export default layoutContentSlice.reducer;
