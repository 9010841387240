import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import { IRelatedArticle } from "models/main/blog";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getArticlesFilteredReq } from "rest-api/main/blog";

export const getNewsArticles = createAsyncThunk<
  { data: IRelatedArticle[]; meta: Pick<EntityListParams, "pagination"> },
  { locations: string[] },
  { state: RootState; rejectValue: ErrorResult }
>(
  "main/blog/newsArticles/getNewsArticles",
  async ({ locations }, { getState, rejectWithValue }) => {
    const result = await getArticlesFilteredReq(
      locations,
      ["news"],
      selectNewsArticlesParams(getState()),
    );

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

const newsArticlesAdapter = createEntityAdapter<IRelatedArticle>({});

export const selectNewsArticlesData = ({ main }: RootState) => main.blog.newsArticles;

export const { selectAll: selectNewsArticles, selectById: selectNewsArticlesById } =
  newsArticlesAdapter.getSelectors<RootState>(({ main }) => main.blog.newsArticles);

export const selectNewsArticlesMeta = ({ main }: RootState) => main.blog.newsArticles.meta;

export const selectNewsArticlesParams = createSelector(
  [selectNewsArticlesData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = newsArticlesAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: 3 },
  meta: {
    requestStatus: "initial",
  },
});

const newsArticlesSlice = createSlice({
  name: "main/blog/newsArticles",
  initialState,
  reducers: {
    setNewsArticlesParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsArticles.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getNewsArticles.fulfilled, (state, { payload }) => {
        newsArticlesAdapter.upsertMany(state, payload.data);
        state.pagination = payload.meta.pagination;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getNewsArticles.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setNewsArticlesParams } = newsArticlesSlice.actions;

export default newsArticlesSlice.reducer;
