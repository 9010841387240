import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IRegisterContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getRegisterContentReq } from "rest-api/auth/register";

export const getRegisterContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("auth/register/content/getRegisterContent", async (_, { rejectWithValue }) => {
  const result = await getRegisterContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectAuthRegisterContent = ({ auth }: RootState) => auth.register.content;

const initialState: IRegisterContent = {
  title: "",
  content: "",
  directions: [],
  switchToLoginText: "",
};

const authRegisterContentSlice = createSlice({
  name: "auth/register/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRegisterContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IRegisterContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default authRegisterContentSlice.reducer;
