import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IForgotPasswordContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getForgotPasswordContentReq } from "rest-api/auth/forgot-password";

export const getForgotPasswordContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("auth/forgotPassword/content/getForgotPasswordContent", async (_, { rejectWithValue }) => {
  const result = await getForgotPasswordContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

const initialState: IForgotPasswordContent = {
  title: "",
  content: "",
  form: { fields: [], submitButtonText: "" },
};

const authForgotPasswordContentSlice = createSlice({
  name: "auth/forgotPassword/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getForgotPasswordContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IForgotPasswordContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export const selectAuthForgotPasswordContent = ({ auth }: RootState) => auth.forgotPassword.content;

export default authForgotPasswordContentSlice.reducer;
