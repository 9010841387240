import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IFindExpertsContent } from "models/dashboard/consumer/find-experts";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerFindExpertsContentReq } from "rest-api/dashboard/consumer/find-expert";

export const getConsumerFindExpertsContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/findExperts/content/getConsumerFindExpertsContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerFindExpertsContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerFindExpertsContent = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.content;

const initialState: IFindExpertsContent = {
  title: "",
  subtitle: "",
  infoSection: { title: "", items: [] },
  actions: [],
};

const contentSlice = createSlice({
  name: "dashboard/consumer/findExperts/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerFindExpertsContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IFindExpertsContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
