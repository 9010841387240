import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IBuyerSellerRegisterContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerRegisterContentReq } from "rest-api/auth/consumer-register";
import { getLocationsFieldReq } from "rest-api/fields";

export const getConsumerRegisterContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("auth/register/consumer/content/getConsumerRegisterContent", async (_, { rejectWithValue }) => {
  const result = await getConsumerRegisterContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  const locationsFieldResult = await getLocationsFieldReq();

  if (isError(locationsFieldResult)) {
    return rejectWithValue(locationsFieldResult);
  }

  result.questionnaireSteps.forEach((s) => {
    if (s.type !== "ComponentConsumerRegisterStepQuestions") return;

    s.forms.forEach((form) => {
      const locationField = {
        ...locationsFieldResult,
        name: `expertQuestions.${form.name}_locations`,
      };
      form.fields = [locationField, ...form.fields];
    });
  });

  return result;
});

export const selectAuthConsumerRegisterContent = ({ auth }: RootState) =>
  auth.register.consumer.content;

const initialState: IBuyerSellerRegisterContent = {
  questionnaireSteps: [],
  registerSection: {
    title: "",
    content: "",
    socialLogins: [],
    switchToLoginText: "",
  },
  emailRegisterSection: {
    title: "",
    form: { fields: [], submitButtonText: "" },
  },
  verifyEmailSection: {
    title: "",
    content: "",
    resendButtonText: "",
  },
  resendVerifyEmailSection: {
    title: "",
    content: "",
    resendButtonText: "",
  },
  socialRegisterSection: {
    title: "",
    content: "",
    form: { fields: [], submitButtonText: "" },
  },
};

const contentSlice = createSlice({
  name: "auth/register/consumer/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerRegisterContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IBuyerSellerRegisterContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
