import { configureStore, Middleware, UnknownAction } from "@reduxjs/toolkit";
import isPlainObject from "lodash/isPlainObject";
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { createLogger } from "redux-logger";
import { combinedReducer } from "./reducer";

const middlewares: Middleware[] = [];
const isBrowser = typeof window !== "undefined";

if (process.env.NODE_ENV === "development" && isBrowser) {
  const logger: Middleware = createLogger({
    collapsed: (_getState, _action, logEntry) => !logEntry?.error,
  });

  middlewares.push(logger);
}

const isEmptyValue = (value: any) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === "number" && isNaN(value)) ||
    (Array.isArray(value) && value.length === 0) ||
    value === ""
  );
};

const deepMerge = (state: any, payload: any) => {
  const nextState = { ...state };

  for (let key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      const payloadValue = payload[key];
      const stateValue = state?.[key];
      if (payloadValue !== undefined && !isEmptyValue(payloadValue)) {
        if (Array.isArray(payloadValue) && !payloadValue.length) {
          nextState[key] = stateValue;
        } else if (isPlainObject(payloadValue)) {
          nextState[key] = deepMerge(stateValue, payloadValue); // Recursive call for nested objects
        } else {
          if (key !== "requestStatus" && key !== "error") nextState[key] = payloadValue; // Replace with new value if it's not empty
        }
      }
      if (stateValue === undefined) {
        nextState[key] = payloadValue; // Additional fields from the payload
      }
    }
  }
  return nextState;
};

const reducer: typeof combinedReducer = (state, action: UnknownAction) => {
  if (action.type === HYDRATE) {
    return deepMerge(state, action.payload);
  } else {
    return combinedReducer(state, action);
  }
};

const makeStore = () =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(middlewares),
    devTools: process.env.NODE_ENV === "development",
  });

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const wrapper = createWrapper<AppStore>(makeStore);
