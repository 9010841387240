import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IDefaultSeo, ISeo } from "models/shared";
import { HYDRATE } from "next-redux-wrapper";
import { getBlogSEOReq, getDefaultSeoReq, getSEOReq } from "rest-api/seo";

export const getDefaultSeo = createAsyncThunk<IDefaultSeo, void, { rejectValue: ErrorResult }>(
  "shared/seo/getDefaultSeo",
  async (_, { rejectWithValue }) => {
    const result = await getDefaultSeoReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getSeo = createAsyncThunk<typeof initialState, string, { rejectValue: ErrorResult }>(
  "shared/seo/getSeo",
  async (path, { rejectWithValue }) => {
    const defaultSeoData = await getDefaultSeoReq();
    const pageSeoData = await getSEOReq(path);

    if (isError(defaultSeoData)) {
      return rejectWithValue(defaultSeoData);
    }

    if (isError(pageSeoData)) {
      return rejectWithValue(pageSeoData);
    }

    return { ...defaultSeoData, ...pageSeoData };
  },
);

export const getBlogSeo = createAsyncThunk<
  typeof initialState,
  string,
  { rejectValue: ErrorResult }
>("shared/seo/getBlogSeo", async (slug, { rejectWithValue }) => {
  const defaultSeoData = await getDefaultSeoReq();
  const articleSeoData = await getBlogSEOReq(slug);

  if (isError(defaultSeoData)) {
    return rejectWithValue(defaultSeoData);
  }

  if (isError(articleSeoData)) {
    return rejectWithValue(articleSeoData);
  }

  return { ...defaultSeoData, ...articleSeoData };
});

export const selectSharedSeo = ({ shared }: RootState) => shared.seo;

const initialState: ISeo & IDefaultSeo = {
  siteName: "",
  url: "",
  defaultMetaImage: { url: "", alternativeText: "" },
  metaTitle: "",
  metaDescription: "",
  metaUrl: "",
};

const seoSlice = createSlice({
  name: "shared/seo",
  initialState,
  reducers: {
    setSeo: (state, action: PayloadAction<Partial<ISeo>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDefaultSeo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(getSeo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(getBlogSeo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(HYDRATE, (state, action: PayloadAction<ISeo, typeof HYDRATE>) => ({
        ...state,
        ...action.payload,
      }));
  },
});

export const { setSeo } = seoSlice.actions;

export default seoSlice.reducer;
