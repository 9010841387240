import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IArticleContent } from "models/main/blog";
import { HYDRATE } from "next-redux-wrapper";
import { getArticleContentReq } from "rest-api/main/blog/article";

export const getArticleContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/blog/article/pageContent/getArticleContent", async (_, { rejectWithValue }) => {
  const result = await getArticleContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectBlogArticlePageContent = ({ main }: RootState) => main.blog.article.pageContent;

const initialState: IArticleContent = {
  headerTitle: "",
  relatedArticlesSection: {
    title: "",
    categories: [],
  },
};

const contentSlice = createSlice({
  name: "main/blog/article/pageContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticleContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IArticleContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
