import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IAboutContent } from "models/main/about";
import { heroSectionPopulateKeys } from "models/main/shared";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getAboutContentReq = () => {
  return axiosInstance
    .get(`/api/about`, {
      params: {
        fields: ["id"],
        populate: {
          hero: heroSectionPopulateKeys,
          whatSetsUsApartSection: {
            populate: {
              items: { populate: { icon: imagePopulate } },
              button: true,
            },
          },
          ourVisionSection: { populate: { image: imagePopulate } },
          helpSection: { populate: "*" },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IAboutContent>)
    .catch(getError);
};
