import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IPrivacyPolicyContent } from "models/main/privacy-policy";
import { HYDRATE } from "next-redux-wrapper";
import { getPrivacyPolicyContentReq } from "rest-api/main/privacy-policy";

export const getPrivacyPolicyContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/privacyPolicy/sections/getPrivacyPolicyContent", async (_, { rejectWithValue }) => {
  const result = await getPrivacyPolicyContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectMainPrivacyPolicyContent = ({ main }: RootState) => main.privacyPolicy.content;
export const selectMainPrivacyPolicyContentHero = ({ main }: RootState) =>
  main.privacyPolicy.content.hero;

const initialState: IPrivacyPolicyContent = {
  hero: {
    title: "",
    titleRotateWords: [],
    subtitle: "",
    content: "",
    button: {
      text: "",
      path: "",
    },
  },
  content: "",
};

const contentSlice = createSlice({
  name: "main/privacyPolicy/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPrivacyPolicyContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IPrivacyPolicyContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
