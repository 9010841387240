import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";
import { ReactNode } from "react";

interface IInitialState {
  show: boolean;
  options: {
    anchorOrigin?: {
      vertical: "top" | "bottom";
      horizontal: "left" | "center" | "right";
    };
    autoHideDuration?: number;
    message: string | ReactNode;
    variant: AlertColor | "custom";
    disableIcon?: boolean;
    actions?: { name: string; onClick: () => void }[];
    transition?: "slide" | "default";
  };
}

const initialState: IInitialState = {
  show: false,
  options: {
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    autoHideDuration: 6000,
    message: "",
    variant: "info",
    disableIcon: false,
    actions: [],
    transition: "default",
  },
};

const messageSlice = createSlice({
  name: "shared/message",
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<IInitialState["options"]>) => {
      state.show = true;
      state.options = {
        ...initialState.options,
        ...action.payload,
      };
    },
    hideMessage: (state) => {
      state.show = false;
    },
  },
});

export const selectSharedLayoutMessage = ({ shared }: RootState) => shared.layout.message;

export const { hideMessage, showMessage } = messageSlice.actions;

export default messageSlice.reducer;
