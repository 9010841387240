import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IBuyerSellerRegisterEmailVerifiedContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerRegisterEmailVerifiedContentReq } from "rest-api/auth/consumer-email-verified";

export const getConsumerRegisterEmailVerifiedContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "auth/register/consumer/emailVerifiedContent/getConsumerRegisterEmailVerifiedContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerRegisterEmailVerifiedContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectAuthConsumerRegisterEmailVerifiedContent = ({ auth }: RootState) =>
  auth.register.consumer.emailVerifiedContent;

const initialState: IBuyerSellerRegisterEmailVerifiedContent = {
  icon: { url: "", alternativeText: "" },
  withReferralTitle: "",
  withoutReferralTitle: "",
  content: "",
  button: {
    path: "",
    text: "",
  },
};

const contentSlice = createSlice({
  name: "auth/register/consumer/emailVerifiedContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerRegisterEmailVerifiedContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IBuyerSellerRegisterEmailVerifiedContent, typeof HYDRATE>) =>
          action.payload,
      );
  },
});

export default contentSlice.reducer;
