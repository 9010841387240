import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IExpertsDirectoryContent } from "models/main/professionals-directory";
import { heroSectionPopulateKeys } from "models/main/shared";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getProfessionalsDirectoryContentReq = () => {
  return axiosInstance
    .get(`/api/professionals-directory`, {
      params: {
        fields: ["id"],
        populate: {
          hero: heroSectionPopulateKeys,
          expertsDirectorySection: {
            populate: {
              items: { populate: { image: imagePopulate, button: true } },
            },
          },
          helpSection: { populate: "*" },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IExpertsDirectoryContent>)
    .catch(getError);
};
