import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IFindExpertsGetStartedContent } from "models/dashboard/consumer/find-experts";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerFindExpertsGetStartedContentReq } from "rest-api/dashboard/consumer/find-expert";
import { getLocationsFieldReq } from "rest-api/fields";

export const getConsumerFindExpertsGetStartedContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/findExperts/getStarted/content/getConsumerFindExpertsGetStartedContent",
  async (_, { rejectWithValue }) => {
    const getStartedContentResult = await getConsumerFindExpertsGetStartedContentReq();

    if (isError(getStartedContentResult)) {
      return rejectWithValue(getStartedContentResult);
    }

    const locationsFieldResult = await getLocationsFieldReq();

    if (isError(locationsFieldResult)) {
      return rejectWithValue(locationsFieldResult);
    }

    getStartedContentResult.steps.forEach((s) => {
      s.forms.forEach((form) => {
        const locationField = {
          ...locationsFieldResult,
          name: `expertQuestions.${form.name}_locations`,
        };
        form.fields = [locationField, ...form.fields];
      });
    });

    return getStartedContentResult;
  },
);

export const selectDashboardConsumerFindExpertsGetStartedContent = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.getStarted.content;

const initialState: IFindExpertsGetStartedContent = {
  steps: [],
};

const contentSlice = createSlice({
  name: "dashboard/consumer/findExperts/getStarted/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerFindExpertsGetStartedContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IFindExpertsGetStartedContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
