import { combineReducers } from "@reduxjs/toolkit";
import findExperts from "../find-experts/store";
import forBuying from "../for-buying/store";
import forSelling from "../for-selling/store";
import layout from "../layout/store";
import profile from "../profile/store";
import ypb from "../ypb/store";

const reducer = combineReducers({
  layout,
  forBuying,
  forSelling,
  profile,
  ypb,
  findExperts,
});

export default reducer;
