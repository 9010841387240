import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IForBuyingContent } from "models/dashboard/consumer/for-buying";
import { formPopulate } from "models/form";
import { transformForm, transformResponse } from "utils/format";

export const getConsumerForBuyingContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-for-buying`, {
      params: {
        populate: {
          ...formPopulate,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IForBuyingContent>)
    .then((data) => transformForm(data, ["form"]))
    .catch(getError);
};
