import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { FormFieldOptions } from "models/form";
import { RequestMeta } from "models/shared";
import { getFieldOptionsReq } from "rest-api/fields";

export const getFieldOptions = createAsyncThunk<
  FormFieldOptions[],
  void,
  { rejectValue: ErrorResult }
>("dashboard/fieldOptions/getFieldOptions", async (_, { rejectWithValue }) => {
  const result = await getFieldOptionsReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;

  //   const resultsFormatted = result.map((r) => ({ ...r, name: r.name.split(".").at(-1)! }));
  //   return resultsFormatted;
});

const fieldOptionsAdapter = createEntityAdapter<FormFieldOptions>({});

export const { selectAll: selectFieldOptions, selectById: selectFieldOptionsById } =
  fieldOptionsAdapter.getSelectors<RootState>(({ dashboard }) => dashboard.fieldOptions);

export const selectFieldOptionsMeta = ({ dashboard }: RootState) => dashboard.fieldOptions.meta;

const initialState = fieldOptionsAdapter.getInitialState<RequestMeta>({
  meta: {
    requestStatus: "initial",
  },
});

const fieldOptionsSlice = createSlice({
  name: "dashboard/fieldOptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFieldOptions.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getFieldOptions.fulfilled, (state, action) => {
        fieldOptionsAdapter.upsertMany(state, action.payload);
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getFieldOptions.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export default fieldOptionsSlice.reducer;
