import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IForProfessionalsContent } from "models/main/for-professionals";
import { HYDRATE } from "next-redux-wrapper";
import { getForProfessionalsContentReq } from "rest-api/main/for-professionals";

export const getForProfessionalsContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/for-professionals/sections/getForProfessionalsContent", async (_, { rejectWithValue }) => {
  const result = await getForProfessionalsContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectMainForProfessionalsContent = ({ main }: RootState) =>
  main.forProfessionals.content;
export const selectMainForProfessionalsContentHero = ({ main }: RootState) =>
  main.forProfessionals.content.hero;
export const selectMainForProfessionalsContentHelpSection = ({ main }: RootState) =>
  main.forProfessionals.content.helpSection;

const initialState: IForProfessionalsContent = {
  hero: {
    title: "",
    titleRotateWords: [],
    subtitle: "",
    content: "",
    button: {
      text: "",
      path: "",
    },
  },
  whyJoinSection: {
    title: "",
    items: [],
    button: { text: "", path: "" },
  },
  howToJoinSection: {
    title: "",
    content: "",
    button: { text: "", path: "" },
    image: { url: "", alternativeText: "" },
  },
  helpSection: {
    title: "",
    subtitle: "",
    button: {
      text: "",
      path: "",
    },
  },
};

const contentSlice = createSlice({
  name: "main/forProfessionals/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getForProfessionalsContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IForProfessionalsContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
