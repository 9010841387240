import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IProfessionalDashboardMenus } from "models/dashboard/professional/layout";
import { Menus } from "models/main/shared";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

const transformMenus = (menus: Menus): IProfessionalDashboardMenus => {
  return {
    nav: menus.find((m) => m.slug === "professional-dashboard") ?? { items: [] },
    mobileNav: menus.find((m) => m.slug === "professional-dashboard-mobile") ?? { items: [] },
    userMenu: menus.find((m) => m.slug === "professional-dashboard-user-menu") ?? { items: [] },
  };
};

export const getProfessionalDashboardMenusReq = () => {
  return axiosInstance
    .get(`/api/menus`, {
      params: {
        filters: {
          slug: {
            $in: [
              "professional-dashboard",
              "professional-dashboard-mobile",
              "professional-dashboard-user-menu",
            ],
          },
        },
        fields: ["id", "slug"],
        nested: true,
        populate: { items: { populate: { icon: imagePopulate } } },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<Menus>)
    .then(transformMenus)
    .catch(getError);
};

export const getProfessionalDashboardLayoutReq = async () => {};
