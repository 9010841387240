import { combineReducers } from "@reduxjs/toolkit";
import content from "./articleSlice";
import pageContent from "./contentSlice";

const reducer = combineReducers({
  pageContent,
  content,
});

export default reducer;
