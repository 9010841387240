import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IDeleteAccountContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getDeleteAccountContentReq } from "rest-api/auth/delete-account";

export const getDeleteAccountContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("auth/deleteAccount/content/getDeleteAccountContent", async (_, { rejectWithValue }) => {
  const result = await getDeleteAccountContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

const initialState: IDeleteAccountContent = {
  header: {
    title: "",
    icon: { url: "", alternativeText: "" },
  },
  form: { fields: [], submitButtonText: "" },
};

const authDeleteAccountContentSlice = createSlice({
  name: "auth/deleteAccount/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeleteAccountContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IDeleteAccountContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export const selectAuthDeleteAccountContent = ({ auth }: RootState) => auth.deleteAccount.content;

export default authDeleteAccountContentSlice.reducer;
