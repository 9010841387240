import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IResetPasswordContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getResetPasswordContentReq } from "rest-api/auth/reset-password";

export const getResetPasswordContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("auth/resetPassword/content/getResetPasswordContent", async (_, { rejectWithValue }) => {
  const result = await getResetPasswordContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

const initialState: IResetPasswordContent = {
  title: "",
  content: "",
  form: { fields: [], submitButtonText: "" },
  successSection: {
    icon: { url: "", alternativeText: "" },
    title: "",
    button: { text: "", path: "" },
  },
};

const authResetPasswordContentSlice = createSlice({
  name: "auth/resetPassword/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResetPasswordContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IResetPasswordContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export const selectAuthResetPasswordContent = ({ auth }: RootState) => auth.resetPassword.content;

export default authResetPasswordContentSlice.reducer;
