import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IBuyerSellerRegisterEmailVerifiedContent } from "models/auth";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getConsumerRegisterEmailVerifiedContentReq = () => {
  return axiosInstance
    .get(`/api/email-verified`, {
      params: {
        populate: {
          icon: imagePopulate,
          button: true,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IBuyerSellerRegisterEmailVerifiedContent>)
    .catch(getError);
};
