import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IBuyerChecklistContent } from "models/main/buyer-checklist";
import { HYDRATE } from "next-redux-wrapper";
import { getBuyerChecklistContentReq } from "rest-api/main/buyer-checklist";

export const getBuyerChecklistContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/buyerChecklist/sections/getBuyerChecklistContent", async (_, { rejectWithValue }) => {
  const result = await getBuyerChecklistContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectMainBuyerChecklistContent = ({ main }: RootState) => main.buyerChecklist.content;
export const selectMainBuyerChecklistContentHero = ({ main }: RootState) =>
  main.buyerChecklist.content.hero;
export const selectMainBuyerChecklistContentHelpSection = ({ main }: RootState) =>
  main.buyerChecklist.content.helpSection;

const initialState: IBuyerChecklistContent = {
  hero: {
    title: "",
    titleRotateWords: [],
    subtitle: "",
    content: "",
    button: {
      text: "",
      path: "",
    },
  },
  checklistSection: {
    title: "",
    subtitle: "",
    form: {
      fields: [],
    },
    content: "",
  },
  helpSection: {
    title: "",
    subtitle: "",
    button: {
      text: "",
      path: "",
    },
  },
};

const contentSlice = createSlice({
  name: "main/buyerChecklist/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBuyerChecklistContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IBuyerChecklistContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
