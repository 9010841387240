import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IProfessionalLoginContent } from "models/auth";
import { formPopulate } from "models/form";
import { transformForm, transformResponse } from "utils/format";

export const getProfessionalLoginContentReq = () => {
  return axiosInstance
    .get(`/api/professional-login`, {
      params: {
        populate: formPopulate,
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IProfessionalLoginContent>)
    .then((data) => transformForm(data, ["form"]))
    .catch(getError);
};
