import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSharedLayout } from "components/shared/layout/store/layoutContentSlice";
import { AppDispatch, RootState } from "lib/redux";
import { IDashboardLayout } from "models/dashboard/shared";
import { HYDRATE } from "next-redux-wrapper";

const getDashboardLayoutReq = async (): Promise<IDashboardLayout> => ({
  footer: {
    content: "The real estate professionals connector and guidance at your fingertips",
    copyright: "Your Property Buddies. All rights reserved.",
  },
});

export const getDashboardLayout = createAsyncThunk<
  typeof initialState,
  void,
  { dispatch: AppDispatch }
>("dashboard/layout/content/getDashboardLayout", async (_, { dispatch }) => {
  await dispatch(getSharedLayout());
  const data = await getDashboardLayoutReq();
  return data;
});

export const selectDashboardLayoutContent = ({ dashboard }: RootState) => dashboard.layout.content;

const initialState: IDashboardLayout = {
  footer: {
    content: "",
    copyright: "",
  },
};

const layoutContentSlice = createSlice({
  name: "dashboard/layout/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardLayout.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<typeof initialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export default layoutContentSlice.reducer;
