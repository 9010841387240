import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IYourExpertsContent } from "models/dashboard/consumer/your-experts";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getConsumerYourExpertsContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-your-expert`, {
      params: {
        populate: {
          pendingRequest: {
            populate: { icon: imagePopulate },
          },
          moreExpertsForm: {
            populate: { field: { populate: "*" } },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IYourExpertsContent>)
    .catch(getError);
};
