import { Palette, PaletteColor } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import set from "lodash/set";
import { IImage, ISharedLayout, imagePopulate } from "models/shared";
import { hexToRgb } from "utils/color";
import { transformResponse } from "utils/format";

interface ISharedLayoutResponse
  extends Pick<ISharedLayout, "settings">,
    Pick<ISharedLayout["theme"], "palette"> {
  logoLightBg: IImage;
  logoDarkBg: IImage;
  typography: TypographyOptions & TypographyVariants & {};
  button: ButtonOptions & ButtonVariants;
}

//#region Transform Shared
//#region Palette Transform
function transformPalette(
  palette: ISharedLayoutResponse["palette"],
): ISharedLayoutResponse["palette"] {
  const output = { ...palette };
  for (const colorKey in palette) {
    const colorValue = palette[colorKey as keyof Palette] as PaletteColor;
    for (const key in colorValue) {
      const color = colorValue[key as keyof PaletteColor];
      if (
        [
          "light",
          "main",
          "dark",
          "contrastText",
          "primary",
          "secondary",
          "default",
          "paper",
        ].includes(key) &&
        color
      ) {
        set(output, [colorKey, `${key}Channel`], hexToRgb(color));
      }
    }
  }
  return output;
}
//#endregion

//#region Typography Transform
type TypographyOptions = NonNullable<ISharedLayout["typography"]>;

interface TypographyVariantsResponse {
  desktop: CSSProperties;
  mobile: CSSProperties;
}

interface TypographyVariants {
  h1: TypographyVariantsResponse;
  h2: TypographyVariantsResponse;
  h3: TypographyVariantsResponse;
  h4: TypographyVariantsResponse;
  h5: TypographyVariantsResponse;
  p1: TypographyVariantsResponse;
  p2: TypographyVariantsResponse;
  p3: TypographyVariantsResponse;
}

const transformTypography = (input: ISharedLayoutResponse["typography"]) => {
  const output: TypographyOptions = { htmlFontSize: input.htmlFontSize };
  for (const key in input) {
    if (key !== "id" && key !== "htmlFontSize") {
      const k = key as keyof TypographyVariants;
      const { id, ...desktopStyles } = input[k].desktop;
      const { id: _id, ...mobileStyles } = input[k].mobile;
      output[k] = {
        ...desktopStyles,
        fontSize: desktopStyles.fontSize + "px",
        lineHeight: desktopStyles.lineHeight + "px",
        letterSpacing: desktopStyles.letterSpacing && desktopStyles.letterSpacing + "px",
        "@media (max-width:640px)": {
          ...mobileStyles,
          fontSize: mobileStyles.fontSize + "px",
          lineHeight: mobileStyles.lineHeight + "px",
          letterSpacing: mobileStyles.letterSpacing && mobileStyles.letterSpacing + "px",
        },
      };
    }
  }
  return output;
};
//#endregion

//#region Button Transform
type ButtonOptions = NonNullable<ISharedLayout["button"]>;

interface ButtonVariantsResponse {
  desktop: CSSProperties;
  mobile: CSSProperties;
}

interface ButtonVariants {
  sizeSmall: ButtonVariantsResponse;
  sizeMedium: ButtonVariantsResponse;
  sizeLarge: ButtonVariantsResponse;
}

const transformButton = (input: ISharedLayoutResponse["button"]) => {
  const output: ButtonOptions = {};
  for (const key in input) {
    if (key !== "id") {
      const k = key as keyof ButtonVariants;
      output[k] = {
        ...input[k].desktop,
        "@media (max-width:640px)": {
          ...input[k].mobile,
        },
      };
    }
  }
  return output;
};
//#endregion

const transformShared = (data: ISharedLayoutResponse): ISharedLayout => ({
  ...data,
  logos: { light: data.logoLightBg, dark: data.logoDarkBg },
  theme: { palette: transformPalette(data.palette) },
  typography: transformTypography(data.typography),
  button: transformButton(data.button),
});
//#endregion

export const getSharedLayoutReq = async (): Promise<ISharedLayout | ErrorResult> => {
  return axiosInstance
    .get(`/api/global-layout-setting`, {
      params: {
        fields: ["id"],
        populate: {
          logoLightBg: imagePopulate,
          logoDarkBg: imagePopulate,
          palette: { populate: "*" },
          typography: {
            populate: {
              h1: { populate: "*" },
              h2: { populate: "*" },
              h3: { populate: "*" },
              h4: { populate: "*" },
              h5: { populate: "*" },
              p1: { populate: "*" },
              p2: { populate: "*" },
              p3: { populate: "*" },
            },
          },
          button: {
            populate: {
              sizeSmall: { populate: "*" },
              sizeMedium: { populate: "*" },
              sizeLarge: { populate: "*" },
            },
          },
          settings: true,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<ISharedLayoutResponse>)
    .then(transformShared)
    .catch(getError);
};
