import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IProfessionalRegisterContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getProfessionalRegisterContentReq } from "rest-api/auth/professional-register";

export const getProfessionalRegisterContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "auth/register/professional/content/getProfessionalRegisterContent",
  async (_, { rejectWithValue }) => {
    const result = await getProfessionalRegisterContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectAuthProfessionalRegisterContent = ({ auth }: RootState) =>
  auth.register.professional.content;

const initialState: IProfessionalRegisterContent = {
  steps: [],
  successSection: {
    icon: { url: "", alternativeText: "" },
    title: "",
    content: "",
    button: { text: "", path: "" },
  },
};

const contentSlice = createSlice({
  name: "auth/register/professional/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalRegisterContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IProfessionalRegisterContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
