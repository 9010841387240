import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IPrivacyPolicyContent } from "models/main/privacy-policy";
import { heroSectionPopulateKeys } from "models/main/shared";
import { transformResponse } from "utils/format";

export const getPrivacyPolicyContentReq = () => {
  return axiosInstance
    .get(`/api/privacy-policy`, {
      params: {
        fields: ["id", "content"],
        populate: { hero: heroSectionPopulateKeys },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IPrivacyPolicyContent>)
    .catch(getError);
};
