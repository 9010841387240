import { combineReducers } from "@reduxjs/toolkit";
import deleteAccount from "../delete-account/store";
import forgotPassword from "../forgot-password/store";
import layout from "../layout/store";
import login from "../login/store";
import register from "../register/store";
import resetPassword from "../reset-password/store";

const reducer = combineReducers({
  layout,
  register,
  login,
  forgotPassword,
  resetPassword,
  deleteAccount,
});

export default reducer;
