import { Typography, TypographyProps } from "@mui/material";
import clsx from "lib/clsx";
import { HTMLAttributes, createContext, useContext, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

if (process.env.NODE_ENV !== "production") {
  const consoleError = console.error;
  console.error = (...args) => {
    if (!(typeof args[0] === "string" && args[0].includes("Prop `%s` did not match."))) {
      consoleError(...args);
    }
  };
}

const SectionContext = createContext<{ id: string } | null>(null);

const useSection = () => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error("`SectionTitle` must be used within a `Section`");
  }
  return context;
};

const SectionTitle = (props: TypographyProps) => {
  const { id } = useSection();
  return <Typography {...props} id={id} />;
};

const Section = ({ className, ...props }: HTMLAttributes<HTMLElement>) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SectionContext.Provider value={{ id }}>
      <section {...props} aria-labelledby={id} className={clsx("py-[5.6rem]", className)} />
    </SectionContext.Provider>
  );
};

export { Section, SectionTitle };
