import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSharedLayout } from "components/shared/layout/store/layoutContentSlice";
import { AppDispatch, RootState } from "lib/redux";
import { HYDRATE } from "next-redux-wrapper";

const getAuthLayoutReq = async (): Promise<{}> => ({});

export const getAuthLayout = createAsyncThunk<typeof initialState, void, { dispatch: AppDispatch }>(
  "auth/layout/content/getAuthLayout",
  async (_, { dispatch }) => {
    await dispatch(getSharedLayout());
    const data = await getAuthLayoutReq();
    return data;
  },
);

export const selectAuthLayoutContent = ({ auth }: RootState) => auth.layout.content;

const initialState = {};

const layoutContentSlice = createSlice({
  name: "auth/layout/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthLayout.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<typeof initialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export default layoutContentSlice.reducer;
