import { combineReducers } from "@reduxjs/toolkit";
import article from "../article/store";
import content from "./contentSlice";
import filteredArticles from "./filteredArticlesSlice";
import newsArticles from "./newsArticlesSlice";

const reducer = combineReducers({
  article,
  content,
  newsArticles,
  filteredArticles,
});

export default reducer;
