import { Box } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { IImage } from "models/shared";
import { ReactSVG } from "react-svg";
import Image from "./Image";

interface IProps {
  className?: string;
  sx?: SxProps;
  icon: IImage | undefined;
}

const Icon = ({ className, sx, icon }: IProps) => {
  if (!icon) return null;

  if (icon.mime === "image/svg+xml")
    return (
      <Box
        className={className}
        component={ReactSVG}
        src={
          icon.url.toString().startsWith("/assets")
            ? icon.url
            : process.env.NEXT_PUBLIC_BACKEND_URL + icon.url
        }
        sx={sx}
      />
    );

  return (
    <Box sx={sx}>
      <Image className={className} {...icon} />
    </Box>
  );
};

export default Icon;
