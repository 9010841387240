import { combineReducers } from "@reduxjs/toolkit";
import content from "./contentSlice";
import emailVerifiedContent from "./emailVerifiedContentSlice";
import state from "./stateSlice";

const reducer = combineReducers({
  content,
  emailVerifiedContent,
  state,
});

export default reducer;
