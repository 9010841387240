import { combineReducers } from "@reduxjs/toolkit";
import activeLeads from "./activeLeadsSlice";
import closedLeads from "./closedLeadsSlice";
import content from "./contentSlice";
import convertedLeads from "./convertedLeadsSlice";
import newLeads from "./newLeadsSlice";

const reducer = combineReducers({
  content,
  newLeads,
  activeLeads,
  convertedLeads,
  closedLeads,
});

export default reducer;
