import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IFAQContent } from "models/main/faq";
import { heroSectionPopulateKeys } from "models/main/shared";
import { transformResponse } from "utils/format";

export const getBuyerSellerFAQContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-faq`, {
      params: {
        fields: ["id"],
        populate: { hero: heroSectionPopulateKeys, faqSections: { populate: "*" } },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IFAQContent>)
    .catch(getError);
};
