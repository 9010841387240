import { byCountry } from "country-code-lookup";
import { useEffect, useState } from "react";

const useIPGeoLocation = () => {
  const [location, setLocation] = useState({
    country: "",
    code: "",
  });
  const [loading, setLoading] = useState(true);

  const getGeoInfo = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://ip.guide");
      const data = await response.json();
      const location = data.location;
      setLocation({
        country: location.country,
        code: byCountry(location.country)?.iso2 ?? "",
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return { loading, location };
};

export default useIPGeoLocation;
