import { ButtonProps, Grow } from "@mui/material";
import clsx from "lib/clsx";
import { RootState, useAppSelector } from "lib/redux";
import { RequestMeta } from "models/shared";
import { Button } from ".";

interface IProps extends ButtonProps {
  selectEntityListMeta: (state: RootState) => RequestMeta["meta"];
  hasMore: boolean;
  loadMore: () => void;
}

const LoadMore = ({
  className,
  selectEntityListMeta,
  hasMore,
  loadMore,
  variant,
  color,
  size,
  children,
  ...props
}: IProps) => {
  const { requestStatus: listReqStatus } = useAppSelector(selectEntityListMeta);

  return (
    <Grow
      in={hasMore}
      timeout={300}
      unmountOnExit
      style={{
        transitionDelay: "150ms",
      }}
    >
      <div>
        <Button
          {...props}
          className={clsx("mx-auto flex", className)}
          variant={variant ?? "contained"}
          color={color ?? "primary"}
          size={size ?? "large"}
          loading={listReqStatus === "pending"}
          onClick={loadMore}
        >
          {children || "Load more"}
        </Button>
      </div>
    </Grow>
  );
};

export default LoadMore;
