import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { showMessage } from "components/shared/layout/store/messageSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { AppDispatch, RootState } from "lib/redux";
import merge from "lodash/merge";
import set from "lodash/set";
import { ILeadTransformed } from "models/dashboard/professional/referrals-board";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { createLead } from "rest-api/lead";
import { getRelevantReferralsReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

type MetaWithDynamicPagination = Pick<EntityListParams, "pagination"> & {
  pagination: { ended: number };
};

export const getNewLeads = createAsyncThunk<
  { data: ILeadTransformed[]; meta: MetaWithDynamicPagination },
  void,
  { state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/professional/leadBoard/newLeads/getNewLeads",
  async (_, { getState, rejectWithValue }) => {
    const result = await getRelevantReferralsReq("new", selectNewLeadsParams(getState()));

    if (isError(result)) {
      return rejectWithValue(result);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<ILeadTransformed[]>(result.data, fieldOptions);
    transformedData.map((r) => {
      if (!r.buyerSeller) return;

      const transformedBuyerSellerData = transformEntityResponse<ILeadTransformed["buyerSeller"]>(
        r.buyerSeller,
        fieldOptions,
      );
      set(r, "buyerSeller", transformedBuyerSellerData);

      const formattedExpertQuestionsResult = transformEntityResponse<
        ILeadTransformed["expertQuestions"]
      >(
        r.expertQuestions,
        [...fieldOptions].map((o) => {
          if (o.name === "locations") {
            o.name = `${r.job}_locations`;
          }
          return o;
        }),
      );
      set(r, "expertQuestions", formattedExpertQuestionsResult);
    });

    return { data: transformedData, meta: result.meta as MetaWithDynamicPagination };
  },
);

export const createNewLead = createAsyncThunk<
  ILeadTransformed | undefined,
  { referralId: string },
  { state: RootState; dispatch: AppDispatch; rejectValue: ErrorResult }
>(
  "dashboard/professional/leadBoard/newLeads/createNewLead",
  async ({ referralId }, { getState, dispatch, rejectWithValue }) => {
    const result = await createLead({ referral: referralId });

    if (isError(result)) {
      dispatch(
        showMessage({
          message: `Failed to acquire the referral: ${result.error.message}`,
          variant: "error",
        }),
      );
      return;
    }

    const boughtLeadResult = await getRelevantReferralsReq("new", { filters: { id: referralId } });

    if (isError(boughtLeadResult)) {
      return rejectWithValue(boughtLeadResult);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<ILeadTransformed[]>(
      boughtLeadResult.data,
      fieldOptions,
    );
    transformedData.map((r) => {
      if (!r.buyerSeller) return;

      const transformedBuyerSellerData = transformEntityResponse<ILeadTransformed["buyerSeller"]>(
        r.buyerSeller,
        fieldOptions,
      );
      set(r, "buyerSeller", transformedBuyerSellerData);

      const formattedExpertQuestionsResult = transformEntityResponse<
        ILeadTransformed["expertQuestions"]
      >(
        r.expertQuestions,
        [...fieldOptions].map((o) => {
          if (o.name === "locations") {
            o.name = `${r.job}_locations`;
          }
          return o;
        }),
      );
      set(r, "expertQuestions", formattedExpertQuestionsResult);
    });

    return transformedData[0];
  },
);

const newLeadsAdapter = createEntityAdapter<ILeadTransformed>({});

export const selectNewLeadsData = ({ dashboard }: RootState) =>
  dashboard.professional.leadBoard.newLeads;

export const { selectAll: selectNewLeads, selectById: selectNewLeadsById } =
  newLeadsAdapter.getSelectors<RootState>(
    ({ dashboard }) => dashboard.professional.leadBoard.newLeads,
  );

export const selectNewLeadsMeta = ({ dashboard }: RootState) =>
  dashboard.professional.leadBoard.newLeads.meta;

export const selectNewLeadsParams = createSelector(
  [selectNewLeadsData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = newLeadsAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: 12 },
  meta: {
    requestStatus: "initial",
  },
});

const newLeadsSlice = createSlice({
  name: "dashboard/professional/leadBoard/newLeads",
  initialState,
  reducers: {
    setNewLeadsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
    removeFromNewLead: (state, { payload }: PayloadAction<ILeadTransformed["id"]>) => {
      newLeadsAdapter.removeOne(state, payload);
      if (state.pagination.total) state.pagination.total -= 1;
    },
    resetNewLeads: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewLeads.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getNewLeads.fulfilled, (state, { payload }) => {
        newLeadsAdapter.upsertMany(state, payload.data);

        const pagination = payload.meta.pagination;
        const { total, ended } = pagination;
        const actualTotal = (state.pagination.total || total!) - ended;
        state.pagination = { ...pagination, total: actualTotal };

        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getNewLeads.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
    builder.addCase(createNewLead.fulfilled, (state, { payload }) => {
      if (payload) newLeadsAdapter.upsertOne(state, payload);
    });
  },
});

export const { setNewLeadsParams, removeFromNewLead, resetNewLeads } = newLeadsSlice.actions;

export default newLeadsSlice.reducer;
