import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import set from "lodash/set";
import { ILeadTransformed } from "models/dashboard/professional/referrals-board";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getRelevantReferralsReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

export const getConvertedLeads = createAsyncThunk<
  { data: ILeadTransformed[]; meta: Pick<EntityListParams, "pagination"> },
  void,
  { state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/professional/leadBoard/convertedLeads/getConvertedLeads",
  async (_, { getState, rejectWithValue }) => {
    const result = await getRelevantReferralsReq(
      "converted",
      selectConvertedLeadsParams(getState()),
    );

    if (isError(result)) {
      return rejectWithValue(result);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<ILeadTransformed[]>(result.data, fieldOptions);
    transformedData.map((r) => {
      if (!r.buyerSeller) return;

      const transformedBuyerSellerData = transformEntityResponse<ILeadTransformed["buyerSeller"]>(
        r.buyerSeller,
        fieldOptions,
      );
      set(r, "buyerSeller", transformedBuyerSellerData);

      const formattedExpertQuestionsResult = transformEntityResponse<
        ILeadTransformed["expertQuestions"]
      >(
        r.expertQuestions,
        [...fieldOptions].map((o) => {
          if (o.name === "locations") {
            o.name = `${r.job}_locations`;
          }
          return o;
        }),
      );
      set(r, "expertQuestions", formattedExpertQuestionsResult);
    });

    return { data: transformedData, meta: result.meta };
  },
);

const convertedLeadsAdapter = createEntityAdapter<ILeadTransformed>({});

export const selectConvertedLeadsData = ({ dashboard }: RootState) =>
  dashboard.professional.leadBoard.convertedLeads;

export const {
  selectAll: selectConvertedLeads,
  selectById: selectConvertedLeadsById,
  selectTotal: selectConvertedLeadsTotal,
} = convertedLeadsAdapter.getSelectors<RootState>(
  ({ dashboard }) => dashboard.professional.leadBoard.convertedLeads,
);

export const selectConvertedLeadsMeta = ({ dashboard }: RootState) =>
  dashboard.professional.leadBoard.convertedLeads.meta;

export const selectConvertedLeadsParams = createSelector(
  [selectConvertedLeadsData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = convertedLeadsAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: 12 },
  meta: {
    requestStatus: "initial",
  },
});

const convertedLeadsSlice = createSlice({
  name: "dashboard/professional/leadBoard/convertedLeads",
  initialState,
  reducers: {
    setConvertedLeadsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
    removeFromConvertedLead: (state, { payload }: PayloadAction<ILeadTransformed["id"]>) => {
      convertedLeadsAdapter.removeOne(state, payload);
      if (state.pagination.total) state.pagination.total -= 1;
    },
    resetConvertedLeads: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConvertedLeads.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getConvertedLeads.fulfilled, (state, { payload }) => {
        convertedLeadsAdapter.upsertMany(state, payload.data);
        state.pagination = payload.meta.pagination;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getConvertedLeads.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setConvertedLeadsParams, removeFromConvertedLead, resetConvertedLeads } =
  convertedLeadsSlice.actions;

export default convertedLeadsSlice.reducer;
