import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IConsumerProfileContent } from "models/dashboard/consumer/profile";
import { IProfessionalProfileContent } from "models/dashboard/professional/profile";
import { IUser } from "models/dashboard/shared";
import { formPopulate } from "models/form";
import { transformForm, transformResponse } from "utils/format";

export const getProfessionalProfileContentReq = () => {
  return axiosInstance
    .get(`/api/professional-profile`, {
      params: {
        populate: {
          infoSection: {
            populate: formPopulate,
          },
          securitySection: {
            populate: { ...formPopulate, links: true },
          },
          preferenceSection: {
            populate: { options: true },
          },
          accountSection: true,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IProfessionalProfileContent>)
    .then((data) => transformForm(data, ["infoSection.form", "securitySection.form"]))
    .catch(getError);
};

export const getConsumerProfileContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-profile`, {
      params: {
        populate: {
          infoSection: {
            populate: formPopulate,
          },
          securitySection: {
            populate: { ...formPopulate, links: true },
          },
          preferenceSection: {
            populate: { options: true },
          },
          accountSection: {
            populate: { dialog: true },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IConsumerProfileContent>)
    .then((data) => transformForm(data, ["infoSection.form", "securitySection.form"]))
    .catch(getError);
};

export async function getUserProfile(): Promise<IUser | ErrorResult> {
  return axiosInstance
    .get<IUser>("/api/users/me", {
      params: {
        populate: {
          buyerSeller: { populate: "*" },
          professional: { populate: "*" },
          role: { populate: "*" },
        },
      },
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function updateUserProfile(data: Partial<IUser>): Promise<IUser | ErrorResult> {
  return axiosInstance
    .put<IUser>("/api/users/me", data, {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function subscribeNewsletterDOI(
  data: Pick<IUser, "email">,
): Promise<string | ErrorResult> {
  return axiosInstance
    .post<string>("/api/email/subscribe-newsletter-doi", data)
    .then(({ data }) => data)
    .catch(getError);
}

export async function subscribeNewsletter(): Promise<"ok" | ErrorResult> {
  return axiosInstance
    .post<"ok">("/api/email/subscribe-newsletter", {}, { withCredentials: true })
    .then(({ data }) => data)
    .catch(getError);
}

export async function getIsSubscribedNewsletter() {
  return axiosInstance
    .get<boolean>("/api/email/is-subscribed-newsletter", {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function unsubscribeNewsletter(): Promise<"ok" | ErrorResult> {
  return axiosInstance
    .post<"ok">("/api/email/unsubscribe-newsletter", {}, { withCredentials: true })
    .then(({ data }) => data)
    .catch(getError);
}
