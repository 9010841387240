import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import merge from "lodash/merge";
import { IReferral } from "models/dashboard/consumer/referral";
import { ILead, LeadStatus } from "models/dashboard/professional/referrals-board";
import { EntityListParams } from "models/shared";
import { transformResponse } from "utils/format";

export async function getReferralsReq(
  params: EntityListParams,
): Promise<IReferral[] | ErrorResult> {
  return axiosInstance
    .get<{ data: { attributes: IReferral }[] }>("/api/referrals", {
      params: {
        filters: { buyerSeller: { id: "me" } },
        sort: "updatedAt:desc",
        populate: {
          lead: { populate: { professional: { fields: ["firstName", "lastName"] } } },
          relatedReferrals: { fields: ["id"] },
        },
        ...params,
      },
      withCredentials: true,
    })
    .then(({ data }) => data)
    .then(({ data }) => transformResponse<IReferral[]>(data))
    .catch(getError);
}

export async function getReferralReq(id: string): Promise<IReferral | ErrorResult> {
  return axiosInstance
    .get<{ data: { attributes: IReferral } }>(`/api/referrals/${id}`, {
      params: {
        populate: {
          lead: { professional: { fields: ["firstName", "lastName"] } },
        },
      },
      withCredentials: true,
    })
    .then(({ data }) => data)
    .then(({ data }) => transformResponse<IReferral>(data))
    .catch(getError);
}

export async function createManyReferrals(data: {
  experts: string[];
  expertQuestions: object;
}): Promise<{ id: string }[] | ErrorResult> {
  return axiosInstance
    .post<{ id: string }[]>(
      "/api/referrals/many",
      { data },
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}

export async function requestAnother(
  id: string,
  data?: {
    expertQuestions: object;
  },
): Promise<{ id: string }[] | ErrorResult> {
  return axiosInstance
    .post<{ id: string }[]>(
      `/api/referrals/request-another/${id}`,
      { data },
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}

export async function getRelevantReferralsReq(
  status: LeadStatus,
  params: EntityListParams | any,
): Promise<{ data: ILead[]; meta: Pick<EntityListParams, "pagination"> } | ErrorResult> {
  return axiosInstance
    .get<{ data: ILead[]; meta: Pick<EntityListParams, "pagination"> }>(
      `/api/referrals/relevant/${status}`,
      {
        params: merge(
          {
            sort: "updatedAt:desc",
            populate: {
              buyerSeller: { populate: ["buyerSeller"] },
              lead: true,
            },
          },
          params,
        ),
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .then(({ data, meta }) => ({ data: transformResponse<ILead[]>(data), meta }))
    .catch(getError);
}
