import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IRegisterContent } from "models/auth";
import { transformResponse } from "utils/format";

export const getRegisterContentReq = () => {
  return axiosInstance
    .get(`/api/register`, {
      params: {
        populate: {
          directions: {
            populate: {
              button: true,
            },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IRegisterContent>)
    .catch(getError);
};
