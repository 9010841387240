import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import {
  IRequestAnotherExpertContent,
  IRequestAnotherExpertEditContent,
} from "models/dashboard/consumer/find-experts";
import { formPopulate } from "models/form";
import { transformForm, transformResponse } from "utils/format";

export const getConsumerRequestAnotherExpertContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-request-another`, {
      params: {
        populate: {
          infoSection: {
            populate: { items: true },
          },
          actions: {
            populate: { button: true },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IRequestAnotherExpertContent>)
    .catch(getError);
};

export const getConsumerRequestAnotherExpertEditContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-request-another-edit`, {
      params: {
        populate: {
          steps: {
            populate: {
              forms: formPopulate["form"],
            },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IRequestAnotherExpertEditContent>)
    .then((data) => {
      for (let s of data.steps) {
        for (let form of s.forms) {
          transformForm({ form }, ["form"]);
        }
      }
      return data;
    })
    .catch(getError);
};
