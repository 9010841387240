import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { ISellerChecklistContent } from "models/main/owner-checklist";
import { HYDRATE } from "next-redux-wrapper";
import { getSellerChecklistContentReq } from "rest-api/main/owner-checklist";

export const getSellerChecklistContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/ownerChecklist/sections/getSellerChecklistContent", async (_, { rejectWithValue }) => {
  const result = await getSellerChecklistContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectMainSellerChecklistContent = ({ main }: RootState) =>
  main.ownerChecklist.content;
export const selectMainSellerChecklistContentHero = ({ main }: RootState) =>
  main.ownerChecklist.content.hero;
export const selectMainSellerChecklistContentHelpSection = ({ main }: RootState) =>
  main.ownerChecklist.content.helpSection;

const initialState: ISellerChecklistContent = {
  hero: {
    title: "",
    titleRotateWords: [],
    subtitle: "",
    content: "",
    button: {
      text: "",
      path: "",
    },
  },
  checklistSection: {
    title: "",
    subtitle: "",
    form: {
      fields: [],
    },
    content: "",
  },
  helpSection: {
    title: "",
    subtitle: "",
    button: {
      text: "",
      path: "",
    },
  },
};

const contentSlice = createSlice({
  name: "main/ownerChecklist/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSellerChecklistContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<ISellerChecklistContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
