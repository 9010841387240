import { combineReducers } from "@reduxjs/toolkit";
import consumer from "../consumer/store";
import professional from "../professional/store";
import content from "./contentSlice";

const reducer = combineReducers({
  content,
  professional,
  consumer,
});

export default reducer;
