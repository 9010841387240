import { IFormComponent } from "models/form";
import { IImage, ILinkButton, imagePopulate } from "models/shared";

//#region Navigation Main
export interface INavItem {
  title: string;
  url?: string;
  icon?: IImage;
  target?: "_blank" | "_parent" | "_self" | "_top";
  variant?: "collapse" | "text" | "button" | "divider";
  children?: INavItem[];
}

export interface INav {
  items: INavItem[];
}

export type Menus = (INav & { slug: string })[];
//#endregion

//#region Navigation Drawer
export interface INavDrawerItemProps {
  item: INavItem;
  nestedLevel: number;
}
//#endregion

//#region Layout
export interface IMainMenus {
  mainNav: INav;
  mainNavDrawer: INav;
  footer: {
    nav: INav;
  };
}

export interface IMainLayoutContent {
  newsletter: INewsletterSection;
  footer: {
    content: string;
    disclaimer: string;
  };
  socials: { title: string; icon: IImage; url: string }[];
}

export type MainLayout = IMainMenus & IMainLayoutContent & {};
//#endregion

//#region Sections

export const heroSectionPopulateKeys = {
  populate: { titleRotateWords: true, image: imagePopulate, button: true },
};
export interface IHeroSection {
  title: string;
  titleRotateWords?: { word: string }[];
  subtitle?: string;
  content?: string;
  button?: ILinkButton;
  image?: IImage;
}

export interface INewsletterSection {
  title: string;
  subtitle: string;
  form: IFormComponent;
  variant: "light" | "dark";
}

export interface IHelpSection {
  title: string;
  subtitle: string;
  button: ILinkButton;
}
//#endregion
