import { useRouter } from "next/router";

const usePageType = () => {
  const { pathname } = useRouter();
  const pageType = pathname.split("/")[1];

  return pageType === "auth" || pageType === "professional" || pageType === "buyer-owner"
    ? pageType
    : "main";
};

export default usePageType;
