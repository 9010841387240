import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";

export async function createLead(data?: {
  referral: string;
}): Promise<{ id: string }[] | ErrorResult> {
  return axiosInstance
    .post<{ id: string }[]>(
      "/api/leads",
      { data },
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}

export async function updateLeadStatus(
  id: string,
  data?: {
    status: string;
  },
): Promise<{ id: string }[] | ErrorResult> {
  return axiosInstance
    .put<{ id: string }[]>(
      `/api/leads/${id}`,
      { data },
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}
