import { combineReducers } from "@reduxjs/toolkit";
import content from "./layoutContentSlice";
import message from "./messageSlice";

const reducer = combineReducers({
  content,
  message,
});

export default reducer;
