import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import { IRelatedArticle } from "models/main/blog";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getArticlesFilteredReq } from "rest-api/main/blog";

export const getFilteredArticles = createAsyncThunk<
  { data: IRelatedArticle[]; meta: Pick<EntityListParams, "pagination"> },
  { locations: string[]; categories: string[] },
  { state: RootState; rejectValue: ErrorResult }
>(
  "main/blog/filteredArticles/getFilteredArticles",
  async ({ locations, categories }, { getState, rejectWithValue }) => {
    const result = await getArticlesFilteredReq(
      locations,
      categories,
      selectFilteredArticlesParams(getState()),
    );

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

const filteredArticlesAdapter = createEntityAdapter<IRelatedArticle>({});

export const selectFilteredArticlesData = ({ main }: RootState) => main.blog.filteredArticles;

export const { selectAll: selectFilteredArticles, selectById: selectFilteredArticlesById } =
  filteredArticlesAdapter.getSelectors<RootState>(({ main }) => main.blog.filteredArticles);

export const selectFilteredArticlesMeta = ({ main }: RootState) => main.blog.filteredArticles.meta;

export const selectFilteredArticlesParams = createSelector(
  [selectFilteredArticlesData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = filteredArticlesAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: 12 },
  meta: {
    requestStatus: "initial",
  },
});

const filteredArticlesSlice = createSlice({
  name: "main/blog/filteredArticles",
  initialState,
  reducers: {
    setFilteredArticlesParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
    resetFilteredArticlesParams: (state) => {
      state.pagination = initialState.pagination;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredArticles.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getFilteredArticles.fulfilled, (state, { payload }) => {
        if (state.pagination.start > 0) {
          filteredArticlesAdapter.upsertMany(state, payload.data);
        } else {
          filteredArticlesAdapter.setAll(state, payload.data);
        }
        state.pagination = payload.meta.pagination;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getFilteredArticles.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setFilteredArticlesParams, resetFilteredArticlesParams } =
  filteredArticlesSlice.actions;

export default filteredArticlesSlice.reducer;
