import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { formPopulate } from "models/form";
import { IMainLayoutContent, IMainMenus, Menus } from "models/main/shared";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

const transformMenus = (menus: Menus): IMainMenus => {
  return {
    mainNav: menus.find((m) => m.slug === "main") ?? { items: [] },
    mainNavDrawer: menus.find((m) => m.slug === "main-mobile-drawer") ?? { items: [] },
    footer: { nav: menus.find((m) => m.slug === "main-footer") ?? { items: [] } },
  };
};

export const getMainMenusReq = () => {
  return axiosInstance
    .get(`/api/menus`, {
      params: {
        filters: { slug: { $in: ["main", "main-mobile-drawer", "main-footer"] } },
        fields: ["id", "slug"],
        nested: true,
        populate: "*",
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<Menus>)
    .then(transformMenus)
    .catch(getError);
};

export const getMainLayoutReq = () => {
  return axiosInstance
    .get(`/api/main-layout-setting`, {
      params: {
        fields: ["id"],
        populate: {
          newsletter: {
            populate: formPopulate,
          },
          footer: true,
          socials: { populate: { icon: imagePopulate } },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IMainLayoutContent>)
    .then((data) => transformForm(data, ["newsletter.form"]))
    .catch(getError);
};
