import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import set from "lodash/set";
import { IUser, IUserTransformed } from "models/dashboard/shared";
import { FormFieldOptions } from "models/form";
import { RequestMeta } from "models/shared";
import { HYDRATE } from "next-redux-wrapper";
import { getIsSubscribedNewsletter, getUserProfile } from "rest-api/user/profile";
import { transformEntityResponse } from "utils/format";

const getProfileFieldOptionsReq = async (): Promise<FormFieldOptions[]> => [
  {
    id: "1",
    name: "goal",
    options: [
      {
        name: "buy",
        label: {
          primary: "Buy real estate",
        },
      },
      {
        name: "sell",
        label: {
          primary: "Sell real estate",
        },
      },
      {
        name: "manage",
        label: {
          primary: "Manage mortgage or property",
        },
      },
    ],
  },
  {
    id: "2",
    name: "propertyType",
    options: [
      {
        name: "residential",
        label: {
          primary: "Residential",
        },
      },
      {
        name: "commercial",
        label: {
          primary: "Commercial",
        },
      },
    ],
  },
  {
    id: "3",
    name: "timeframe",
    options: [
      {
        name: "asap",
        label: {
          primary: "As soon as possible",
        },
      },
      {
        name: "within_6_months",
        label: {
          primary: "Less than 6 months",
        },
      },
      {
        name: "within_1_year",
        label: {
          primary: "Within the next year",
        },
      },
      {
        name: "within_2_years",
        label: {
          primary: "Within the next 2 years",
        },
      },
    ],
  },
  {
    id: "4",
    name: "expertQuestions.real_estate_agent_needAppraisal",
    options: [
      {
        name: "inPerson",
        label: {
          primary: "In person appraisal",
        },
      },
      {
        name: "digital",
        label: {
          primary: "Digital appraisal",
        },
      },
      {
        name: "no",
        label: {
          primary: "I don't need an appraisal",
        },
      },
    ],
  },
  {
    id: "5",
    name: "locations",
    options: [
      {
        name: "auckland",
        label: { primary: "Auckland" },
        groupings: { label: "New Zealand" },
      },
      {
        name: "christchurch",
        label: { primary: "Christchurch" },
        groupings: { label: "New Zealand" },
      },
      {
        name: "wellington",
        label: { primary: "Wellington" },
        groupings: { label: "New Zealand" },
      },
      {
        name: "queenstown",
        label: { primary: "Queenstown" },
        groupings: { label: "New Zealand" },
      },
      {
        name: "melbourne",
        label: { primary: "Melbourne" },
        groupings: { label: "Australia" },
      },
      { name: "sydney", label: { primary: "Sydney" }, groupings: { label: "Australia" } },
      {
        name: "adelaide",
        label: { primary: "Adelaide" },
        groupings: { label: "Australia" },
      },
      {
        name: "brisbane",
        label: { primary: "Brisbane" },
        groupings: { label: "Australia" },
      },
      {
        name: "canberra",
        label: { primary: "Canberra" },
        groupings: { label: "Australia" },
      },
      { name: "perth", label: { primary: "Perth" }, groupings: { label: "Australia" } },
    ],
  },
  {
    id: "6",
    name: "suburbs",
    options: [
      {
        name: "auckland_cbd",
        label: { primary: "Auckland CBD" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "hauraki_gulf_island",
        label: { primary: "Hauraki Gulf Island" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "north_shore_city",
        label: { primary: "North Shore City" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "waitakere_city",
        label: { primary: "Waitakere City" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "waiheke_island",
        label: { primary: "Waiheke Island" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "manukau_city",
        label: { primary: "Manukau City" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "rodney_district",
        label: { primary: "Rodney District" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "papakura_district",
        label: { primary: "Papakura District" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "franklin_district",
        label: { primary: "Franklin District" },
        groupings: {
          label: "Auckland",
          and: [
            {
              key: "locations",
              value: { or: ["auckland"] },
            },
          ],
        },
      },
      {
        name: "queenstown_cbd",
        label: { primary: "Queenstown CBD" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "frankton",
        label: { primary: "Frankton" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "arrowtown",
        label: { primary: "Arrowtown" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "kelvin_heights",
        label: { primary: "Kelvin Heights" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "fernhill",
        label: { primary: "Fernhill" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "sunshine_bay",
        label: { primary: "Sunshine Bay" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "lake_hayes_estate",
        label: { primary: "Lake Hayes Estate" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "jack_s_point",
        label: { primary: "Jack's Point" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "arthur_s_point",
        label: { primary: "Arthur's Point" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "closeburn",
        label: { primary: "Closeburn" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "quail_rise",
        label: { primary: "Quail Rise" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },
      {
        name: "shotover_country",
        label: { primary: "Shotover Country" },
        groupings: {
          label: "Queenstown",
          and: [
            {
              key: "locations",
              value: { or: ["queenstown"] },
            },
          ],
        },
      },

      {
        name: "christchurch_central_city",
        label: { primary: "Christchurch Central City" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "riccarton",
        label: { primary: "Riccarton" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "merivale",
        label: { primary: "Merivale" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "st_albans",
        label: { primary: "St Albans" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "papanui",
        label: { primary: "Papanui" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "burnside",
        label: { primary: "Burnside" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "fendalton",
        label: { primary: "Fendalton" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "new_brighton",
        label: { primary: "New Brighton" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "sumner",
        label: { primary: "Sumner" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "lyttelton",
        label: { primary: "Lyttelton" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "halswell",
        label: { primary: "Halswell" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "addington",
        label: { primary: "Addington" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "sydenham",
        label: { primary: "Sydenham" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "cashmere",
        label: { primary: "Cashmere" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "redwood",
        label: { primary: "Redwood" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "avonhead",
        label: { primary: "Avonhead" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "ilam",
        label: { primary: "Ilam" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "shirley",
        label: { primary: "Shirley" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "burwood",
        label: { primary: "Burwood" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "linwood",
        label: { primary: "Linwood" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "woolston",
        label: { primary: "Woolston" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "kaiapoi",
        label: { primary: "Kaiapoi (part of Greater Christchurch)" },
        groupings: {
          label: "Christchurch",
          and: [
            {
              key: "locations",
              value: { or: ["christchurch"] },
            },
          ],
        },
      },
      {
        name: "wellington_cbd",
        label: { primary: "Wellington CBD" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "thorndon",
        label: { primary: "Thorndon" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "te_aro",
        label: { primary: "Te Aro" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "mount_victoria",
        label: { primary: "Mount Victoria" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "oriental_bay",
        label: { primary: "Oriental Bay" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "newtown",
        label: { primary: "Newtown" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "kilbirnie",
        label: { primary: "Kilbirnie" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "miramar",
        label: { primary: "Miramar" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "karori",
        label: { primary: "Karori" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "brooklyn",
        label: { primary: "Brooklyn" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "island_bay",
        label: { primary: "Island Bay" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "hataitai",
        label: { primary: "Hataitai" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "johnsonville",
        label: { primary: "Johnsonville" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "khandallah",
        label: { primary: "Khandallah" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "lyall_bay",
        label: { primary: "Lyall Bay" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "seatoun",
        label: { primary: "Seatoun" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "wadestown",
        label: { primary: "Wadestown" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "wilton",
        label: { primary: "Wilton" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "tawa",
        label: { primary: "Tawa" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "lower_hutt",
        label: { primary: "Lower Hutt (part of Greater Wellington)" },
        groupings: {
          label: "Wellington",
          and: [
            {
              key: "locations",
              value: { or: ["wellington"] },
            },
          ],
        },
      },
      {
        name: "adelaide_cbd",
        label: { primary: "Adelaide CBD" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "north_adelaide",
        label: { primary: "North Adelaide" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "adelaide_hills_region",
        label: { primary: "Adelaide Hills Region" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "norwood_payneham_&_st_peters",
        label: { primary: "Norwood Payneham & St Peters" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "burnside",
        label: { primary: "Burnside" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "campbelltown",
        label: { primary: "Campbelltown" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "charles_sturt",
        label: { primary: "Charles Sturt" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "holdfast_bay",
        label: { primary: "Holdfast Bay" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "marion",
        label: { primary: "Marion" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "mitcham",
        label: { primary: "Mitcham" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "onkaparinga",
        label: { primary: "Onkaparinga" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "playford",
        label: { primary: "Playford" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "port_adelaide_enfield",
        label: { primary: "Port Adelaide Enfield" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "prospect",
        label: { primary: "Prospect" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "salisbury",
        label: { primary: "Salisbury" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "tea_tree_gully",
        label: { primary: "Tea Tree Gully" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "unley",
        label: { primary: "Unley" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "west_torrens",
        label: { primary: "West Torrens" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "walkerville",
        label: { primary: "Walkerville" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "gawler",
        label: { primary: "Gawler" },
        groupings: {
          label: "Adelaide",
          and: [
            {
              key: "locations",
              value: { or: ["adelaide"] },
            },
          ],
        },
      },
      {
        name: "melbourne_cbd",
        label: { primary: "Melbourne CBD" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "north_melbourne",
        label: { primary: "North Melbourne" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "southbank",
        label: { primary: "Southbank" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "docklands",
        label: { primary: "Docklands" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "east_melbourne",
        label: { primary: "East Melbourne" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "west_melbourne",
        label: { primary: "West Melbourne" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "carlton",
        label: { primary: "Carlton" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "fitzroy",
        label: { primary: "Fitzroy" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "richmond",
        label: { primary: "Richmond" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "south_yarra",
        label: { primary: "South Yarra" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "prahran",
        label: { primary: "Prahran" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "st_kilda",
        label: { primary: "St Kilda" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "brunswick",
        label: { primary: "Brunswick" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "collingwood",
        label: { primary: "Collingwood" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "footscray",
        label: { primary: "Footscray" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "hawthorn",
        label: { primary: "Hawthorn" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "toorak",
        label: { primary: "Toorak" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "camberwell",
        label: { primary: "Camberwell" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "malvern",
        label: { primary: "Malvern" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "moonee_ponds",
        label: { primary: "Moonee Ponds" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "box_hill",
        label: { primary: "Box Hill" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "doncaster",
        label: { primary: "Doncaster" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "glen_waverley",
        label: { primary: "Glen Waverley" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "oakleigh",
        label: { primary: "Oakleigh" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "clayton",
        label: { primary: "Clayton" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "dandenong",
        label: { primary: "Dandenong" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "frankston",
        label: { primary: "Frankston" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "werribee",
        label: { primary: "Werribee" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "sunbury",
        label: { primary: "Sunbury" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "craigieburn",
        label: { primary: "Craigieburn" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "ringwood",
        label: { primary: "Ringwood" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "ferntree_gully",
        label: { primary: "Ferntree Gully" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "lilydale",
        label: { primary: "Lilydale" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "mornington",
        label: { primary: "Mornington" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "pakenham",
        label: { primary: "Pakenham" },
        groupings: {
          label: "Melbourne",
          and: [
            {
              key: "locations",
              value: { or: ["melbourne"] },
            },
          ],
        },
      },
      {
        name: "sydney_cbd",
        label: { primary: "Sydney CBD" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "eastern_suburbs",
        label: { primary: "Eastern Suburbs" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "inner_west",
        label: { primary: "Inner West" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "northern_beaches",
        label: { primary: "Northern Beaches" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "north_shore",
        label: { primary: "North Shore" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "hills_district",
        label: { primary: "Hills District" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "southern_sydney",
        label: { primary: "Southern Sydney" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "western_sydney",
        label: { primary: "Western Sydney" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "canterbury-bankstown",
        label: { primary: "Canterbury-Bankstown" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "parramatta",
        label: { primary: "Parramatta" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "st_george",
        label: { primary: "St George" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "sutherland_shire",
        label: { primary: "Sutherland Shire" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "macarthur",
        label: { primary: "Macarthur" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "northern_suburbs",
        label: { primary: "Northern Suburbs" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "south-western_sydney",
        label: { primary: "South-western Sydney" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "the_shire_sutherland_shire",
        label: { primary: "The Shire (Sutherland Shire)" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "inner_city",
        label: { primary: "Inner City" },
        groupings: {
          label: "Sydney",
          and: [
            {
              key: "locations",
              value: { or: ["sydney"] },
            },
          ],
        },
      },
      {
        name: "perth_cbd",
        label: { primary: "Perth CBD" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "northbridge",
        label: { primary: "Northbridge" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "east_perth",
        label: { primary: "East Perth" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "west_perth",
        label: { primary: "West Perth" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "south_perth",
        label: { primary: "South Perth" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "subiaco",
        label: { primary: "Subiaco" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "fremantle",
        label: { primary: "Fremantle" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "joondalup",
        label: { primary: "Joondalup" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "scarborough",
        label: { primary: "Scarborough" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "cottesloe",
        label: { primary: "Cottesloe" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "claremont",
        label: { primary: "Claremont" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "mount_lawley",
        label: { primary: "Mount Lawley" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "leederville",
        label: { primary: "Leederville" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "victoria_park",
        label: { primary: "Victoria Park" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "armadale",
        label: { primary: "Armadale" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "midland",
        label: { primary: "Midland" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "rockingham",
        label: { primary: "Rockingham" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "mandurah",
        label: { primary: "Mandurah" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "kalamunda",
        label: { primary: "Kalamunda" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "cannington",
        label: { primary: "Cannington" },
        groupings: {
          label: "Perth",
          and: [
            {
              key: "locations",
              value: { or: ["perth"] },
            },
          ],
        },
      },
      {
        name: "north_canberra",
        label: { primary: "North Canberra" },
        groupings: {
          label: "Canberra",
          and: [
            {
              key: "locations",
              value: { or: ["canberra"] },
            },
          ],
        },
      },
      {
        name: "south_canberra",
        label: { primary: "South Canberra" },
        groupings: {
          label: "Canberra",
          and: [
            {
              key: "locations",
              value: { or: ["canberra"] },
            },
          ],
        },
      },
      {
        name: "woden_valley",
        label: { primary: "Woden Valley" },
        groupings: {
          label: "Canberra",
          and: [
            {
              key: "locations",
              value: { or: ["canberra"] },
            },
          ],
        },
      },
      {
        name: "belconnen",
        label: { primary: "Belconnen" },
        groupings: {
          label: "Canberra",
          and: [
            {
              key: "locations",
              value: { or: ["canberra"] },
            },
          ],
        },
      },
      {
        name: "tuggeranong",
        label: { primary: "Tuggeranong" },
        groupings: {
          label: "Canberra",
          and: [
            {
              key: "locations",
              value: { or: ["canberra"] },
            },
          ],
        },
      },
      {
        name: "weston_creek",
        label: { primary: "Weston Creek" },
        groupings: {
          label: "Canberra",
          and: [
            {
              key: "locations",
              value: { or: ["canberra"] },
            },
          ],
        },
      },
      {
        name: "gungahlin",
        label: { primary: "Gungahlin" },
        groupings: {
          label: "Canberra",
          and: [
            {
              key: "locations",
              value: { or: ["canberra"] },
            },
          ],
        },
      },
      {
        name: "molonglo_valley",
        label: { primary: "Molonglo Valley" },
        groupings: {
          label: "Canberra",
          and: [
            {
              key: "locations",
              value: { or: ["canberra"] },
            },
          ],
        },
      },
      {
        name: "brisbane_cbd",
        label: { primary: "Brisbane CBD (Central Business District)" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "south_brisbane",
        label: { primary: "South Brisbane" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "west_end",
        label: { primary: "West End" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "fortitude_valley",
        label: { primary: "Fortitude Valley" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "kangaroo_point",
        label: { primary: "Kangaroo Point" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "new_farm",
        label: { primary: "New Farm" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "paddington",
        label: { primary: "Paddington" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "milton",
        label: { primary: "Milton" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "spring_hill",
        label: { primary: "Spring Hill" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "kelvin_grove",
        label: { primary: "Kelvin Grove" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "toowong",
        label: { primary: "Toowong" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "indooroopilly",
        label: { primary: "Indooroopilly" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "st_lucia",
        label: { primary: "St Lucia" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "woolloongabba",
        label: { primary: "Woolloongabba" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "east_brisbane",
        label: { primary: "East Brisbane" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "annerley",
        label: { primary: "Annerley" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "greenslopes",
        label: { primary: "Greenslopes" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "coorparoo",
        label: { primary: "Coorparoo" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "holland_park",
        label: { primary: "Holland Park" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "mount_gravatt",
        label: { primary: "Mount Gravatt" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "sunnybank",
        label: { primary: "Sunnybank" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "carindale",
        label: { primary: "Carindale" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "chermside",
        label: { primary: "Chermside" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "aspley",
        label: { primary: "Aspley" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "nundah",
        label: { primary: "Nundah" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "sandgate",
        label: { primary: "Sandgate" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "redcliffe",
        label: { primary: "Redcliffe" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "wynnum",
        label: { primary: "Wynnum" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "manly",
        label: { primary: "Manly" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "eight_mile_plains",
        label: { primary: "Eight Mile Plains" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "loganholme",
        label: { primary: "Loganholme" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "springwood",
        label: { primary: "Springwood" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "mount_ommaney",
        label: { primary: "Mount Ommaney" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "kenmore",
        label: { primary: "Kenmore" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "upper_kedron",
        label: { primary: "Upper Kedron" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "the_gap",
        label: { primary: "The Gap" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "mitchelton",
        label: { primary: "Mitchelton" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "stafford",
        label: { primary: "Stafford" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "carseldine",
        label: { primary: "Carseldine" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "bridgeman_downs",
        label: { primary: "Bridgeman Downs" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "albany_creek",
        label: { primary: "Albany Creek" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "everton_park",
        label: { primary: "Everton Park" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "bardon",
        label: { primary: "Bardon" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "ferny_grove",
        label: { primary: "Ferny Grove" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "mcdowall",
        label: { primary: "McDowall" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "keperra",
        label: { primary: "Keperra" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "bray_park",
        label: { primary: "Bray Park" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "warner",
        label: { primary: "Warner" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "petrie",
        label: { primary: "Petrie" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
      {
        name: "strathpine",
        label: { primary: "Strathpine" },
        groupings: {
          label: "Brisbane",
          and: [
            {
              key: "locations",
              value: { or: ["brisbane"] },
            },
          ],
        },
      },
    ],
  },
];

export const getProfile = createAsyncThunk<
  { data: IUser; transformedData: IUserTransformed },
  void,
  { rejectValue: ErrorResult }
>("dashboard/profile/getProfile", async (_, { rejectWithValue }) => {
  const result = await getUserProfile();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  const newsletterSubscribedResult = await getIsSubscribedNewsletter();
  const newsletterSubscribed = isError(newsletterSubscribedResult)
    ? false
    : newsletterSubscribedResult;
  result.newsletterSubscribed = newsletterSubscribed;

  const fieldOptions = await getProfileFieldOptionsReq();
  const transformedData = transformEntityResponse<IUserTransformed>(result, fieldOptions);
  if (result.buyerSeller) {
    const formattedBuyerSellerResult = transformEntityResponse<IUserTransformed["buyerSeller"]>(
      result.buyerSeller,
      fieldOptions,
    );
    set(transformedData, "buyerSeller", formattedBuyerSellerResult);
  }

  return { data: result, transformedData };
});

export const selectProfile = ({ dashboard }: RootState) => dashboard.profile;

export const selectProfileData = ({ dashboard }: RootState) => dashboard.profile.data;

export const selectProfileDataTransformed = ({ dashboard }: RootState) =>
  dashboard.profile.transformedData;

export const selectProfileMeta = ({ dashboard }: RootState) => dashboard.profile.meta;

interface IInitialState extends RequestMeta {
  data: IUser;
  transformedData: IUserTransformed;
}

const initialData: IUser & IUserTransformed = {
  id: "",
  questionnaireComplete: false,
  role: { name: null },
  confirmed: true,
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  locations: [],
  suburbs: [],
};

const initialState: IInitialState = {
  data: initialData,
  transformedData: initialData,
  meta: {
    requestStatus: "initial",
  },
};

const profileSlice = createSlice({
  name: "dashboard/profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.transformedData = payload.transformedData;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.meta = { requestStatus: "rejected", ...action.payload };
      })
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IInitialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export default profileSlice.reducer;
