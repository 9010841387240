import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import set from "lodash/set";
import { ILeadTransformed } from "models/dashboard/professional/referrals-board";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getRelevantReferralsReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

export const getActiveLeads = createAsyncThunk<
  { data: ILeadTransformed[]; meta: Pick<EntityListParams, "pagination"> },
  void,
  { state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/professional/leadBoard/activeLeads/getActiveLeads",
  async (_, { getState, rejectWithValue }) => {
    const result = await getRelevantReferralsReq("active", selectActiveLeadsParams(getState()));

    if (isError(result)) {
      return rejectWithValue(result);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<ILeadTransformed[]>(result.data, fieldOptions);
    transformedData.map((r) => {
      if (!r.buyerSeller) return;

      const transformedBuyerSellerData = transformEntityResponse<ILeadTransformed["buyerSeller"]>(
        r.buyerSeller,
        fieldOptions,
      );
      set(r, "buyerSeller", transformedBuyerSellerData);

      const formattedExpertQuestionsResult = transformEntityResponse<
        ILeadTransformed["expertQuestions"]
      >(
        r.expertQuestions,
        [...fieldOptions].map((o) => {
          if (o.name === "locations") {
            o.name = `${r.job}_locations`;
          }
          return o;
        }),
      );
      set(r, "expertQuestions", formattedExpertQuestionsResult);
    });

    return { data: transformedData, meta: result.meta };
  },
);

const activeLeadsAdapter = createEntityAdapter<ILeadTransformed>({});

export const selectActiveLeadsData = ({ dashboard }: RootState) =>
  dashboard.professional.leadBoard.activeLeads;

export const {
  selectAll: selectActiveLeads,
  selectById: selectActiveLeadsById,
  selectTotal: selectActiveLeadsTotal,
} = activeLeadsAdapter.getSelectors<RootState>(
  ({ dashboard }) => dashboard.professional.leadBoard.activeLeads,
);

export const selectActiveLeadsMeta = ({ dashboard }: RootState) =>
  dashboard.professional.leadBoard.activeLeads.meta;

export const selectActiveLeadsParams = createSelector(
  [selectActiveLeadsData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = activeLeadsAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: 12 },
  meta: {
    requestStatus: "initial",
  },
});

const activeLeadsSlice = createSlice({
  name: "dashboard/professional/leadBoard/activeLeads",
  initialState,
  reducers: {
    setActiveLeadsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
    removeFromActiveLead: (state, { payload }: PayloadAction<ILeadTransformed["id"]>) => {
      activeLeadsAdapter.removeOne(state, payload);
      if (state.pagination.total) state.pagination.total -= 1;
    },
    resetActiveLeads: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveLeads.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getActiveLeads.fulfilled, (state, { payload }) => {
        activeLeadsAdapter.upsertMany(state, payload.data);
        state.pagination = payload.meta.pagination;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getActiveLeads.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setActiveLeadsParams, removeFromActiveLead, resetActiveLeads } =
  activeLeadsSlice.actions;

export default activeLeadsSlice.reducer;
