import { CssVarsPalette, CssVarsThemeOptions } from "@mui/material/styles";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography";
import { ISharedLayout } from "models/shared";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    main: string;
    mainChannel: string;
  }

  interface Palette {
    accent1?: Palette["primary"] & CssVarsPalette["primary"];
    accent2?: Palette["primary"] & CssVarsPalette["primary"];
  }
  interface PaletteOptions {
    accent1?: PaletteOptions["primary"] & CssVarsPalette["primary"];
    accent2?: PaletteOptions["primary"] & CssVarsPalette["primary"];
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    p1: TypographyStyleOptions;
    p2: TypographyStyleOptions;
    p3: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    p1?: TypographyStyleOptions;
    p2?: TypographyStyleOptions;
    p3?: TypographyStyleOptions;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    p1: true;
    p2: true;
    p3: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const defaultTheme = ({ theme, typography, button }: ISharedLayout): CssVarsThemeOptions => ({
  cssVarPrefix: "",
  breakpoints: {
    values: {
      xxs: 0,
      xs: 640,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
  },
  colorSchemes: { light: theme },
  typography: {
    fontFamily: ["var(--font-base)"].join(","),
    ...typography,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          p1: "p",
          p2: "p",
          p3: "p",
        },
        variant: "p1",
      },
    },
    MuiButton: { styleOverrides: button },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...typography?.p3,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...typography?.p3,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...typography?.caption,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          ...typography?.p3,
        },
      },
    },
  },
});

export default defaultTheme;
