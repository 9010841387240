import { combineReducers } from "@reduxjs/toolkit";
import nav from "../nav/store";
import content from "./layoutContentSlice";

const reducer = combineReducers({
  nav,
  content,
});

export default reducer;
