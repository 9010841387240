import { combineReducers } from "@reduxjs/toolkit";
import layout from "../layout/store";
import seo from "./seoSlice";

const reducer = combineReducers({
  layout,
  seo,
});

export default reducer;
