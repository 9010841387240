import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IProfessionalLeadBoardContent } from "models/dashboard/professional/referrals-board";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getProfessionalLeadBoardContentReq = () => {
  return axiosInstance
    .get(`/api/professional-lead-board`, {
      params: {
        populate: {
          tabs: true,
          leadsNavLinks: true,
          colors: true,
          leadItem: { populate: "*" },
          states: { populate: { icon: imagePopulate } },
          pricing: true,
          ...formPopulate,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IProfessionalLeadBoardContent>)
    .then((data) => transformForm(data, ["form"]))
    .catch(getError);
};
