import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import merge from "lodash/merge";
import { IBlogContent, IRelatedArticle } from "models/main/blog";
import { EntityListParams, imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getBlogContentReq = () => {
  return axiosInstance
    .get(`/api/blog`, {
      params: {
        populate: {
          featuredArticle: { populate: { featuredImage: imagePopulate } },
          newsSection: true,
          filteredArticlesSection: { populate: "*" },
          serviceRequestedSection: { populate: { icon: imagePopulate, article: true } },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IBlogContent>)
    .catch(getError);
};

export const getPopulatedCategoriesReq = (): Promise<string[] | ErrorResult> => {
  return axiosInstance
    .get(`/api/blog-categories`, {
      params: {
        filters: {
          $or: [{ name: "all" }, { articles: { id: { $notNull: true } } }],
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<{ name: string }[]>)
    .then((data) => data.map(({ name }) => name))
    .catch(getError);
};

export const getArticlesFilteredReq = (
  locations: string[],
  categories: string[],
  params: EntityListParams | any,
): Promise<
  { data: IRelatedArticle[]; meta: Pick<EntityListParams, "pagination"> } | ErrorResult
> => {
  return axiosInstance
    .get(`/api/articles`, {
      params: merge(
        {
          filters: {
            $and: [
              { $or: locations.map((l) => ({ locations: { $containsi: l } })) },
              { $or: categories.map((c) => ({ categories: { name: c } })) },
            ],
          },
          sort: "publishedAt:desc",
          populate: {
            featuredImage: imagePopulate,
          },
        },
        params,
      ),
    })
    .then(({ data }) => data)
    .then(({ data, meta }) => ({ data: transformResponse<IRelatedArticle[]>(data), meta }))
    .catch(getError);
};
