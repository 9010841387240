import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IProfessionalLeadBoardContent } from "models/dashboard/professional/referrals-board";
import { HYDRATE } from "next-redux-wrapper";
import { getProfessionalLeadBoardContentReq } from "rest-api/dashboard/professional/referral-board";

export const getProfessionalLeadBoardContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/professional/leadBoard/content/getProfessionalLeadBoardContent",
  async (_, { rejectWithValue }) => {
    const result = await getProfessionalLeadBoardContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectProfessionalLeadBoardContent = ({ dashboard }: RootState) =>
  dashboard.professional.leadBoard.content;

const initialState: IProfessionalLeadBoardContent = {
  tabs: [],
  leadsNavLinks: [],
  colors: [],
  form: {
    fields: [],
  },
  leadItem: {
    subtitle: "",
    formatting: [],
    warmLeadHeader: {
      title: "",
      content: "",
      buttonText: "",
    },
  },
  states: [],
  pricing: [],
};

const contentSlice = createSlice({
  name: "dashboard/professional/leadBoard/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalLeadBoardContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IProfessionalLeadBoardContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
