import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IArticle, IRelatedArticle } from "models/main/blog";
import { HYDRATE } from "next-redux-wrapper";
import { getArticleReq, getRelatedArticlesReq } from "rest-api/main/blog/article";

export const getArticle = createAsyncThunk<
  typeof initialState,
  { slug: string; publicationState?: "preview" | "live" },
  { rejectValue: ErrorResult }
>("main/blog/article/getArticle", async ({ slug, publicationState }, { rejectWithValue }) => {
  const result = await getArticleReq(slug, publicationState);

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const getRelatedArticles = createAsyncThunk<
  IRelatedArticle[],
  { slug: string; categories: string[]; locations: string[] },
  { rejectValue: ErrorResult }
>(
  "main/blog/article/content/getRelatedArticles",
  async ({ slug, categories, locations }, { rejectWithValue }) => {
    const result = await getRelatedArticlesReq(slug, categories, locations);

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectBlogArticleContent = ({ main }: RootState) => main.blog.article.content;

const initialState: IArticle & { relatedArticles?: IRelatedArticle[] } = {
  id: "",
  title: "",
  slug: "",
  locations: [],
  categories: [],
  audience: [],
  duration: 0,
  excerpt: "",
  featuredImage: { url: "", alternativeText: "" },
  blocks: [],
  createdAt: "",
  updatedAt: "",
  publishedAt: "",
};

const articleSlice = createSlice({
  name: "main/blog/article/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticle.fulfilled, (_, action) => action.payload)
      .addCase(HYDRATE, (_, action: PayloadAction<IArticle, typeof HYDRATE>) => action.payload);
    builder.addCase(getRelatedArticles.fulfilled, (state, action) => {
      state.relatedArticles = action.payload;
    });
  },
});

export default articleSlice.reducer;
