import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectDashboardConsumerFindExpertsEditState = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.edit.state;
export const selectDashboardConsumerFindExpertsEditActiveStep = createSelector(
  (state: RootState) => state.dashboard.consumer.findExperts.edit,
  ({ content: { steps }, state: { activeStepId } }) =>
    steps.find((s) => s.stepId === activeStepId) ?? steps[0],
);
export const selectDashboardConsumerFindExpertsEditActiveSteps = createSelector(
  (state: RootState) => state.dashboard.consumer.findExperts.edit,
  ({ content: { steps }, state: { activeStepId, prevStepIds } }) =>
    steps.filter(
      (s) => !prevStepIds.length || prevStepIds.includes(s.stepId) || s.stepId === activeStepId,
    ),
);

interface IInitialState {
  activeStepId: string;
  prevStepIds: string[];
}

const initialState: IInitialState = {
  activeStepId: "",
  prevStepIds: [],
};

const stateSlice = createSlice({
  name: "dashboard/consumer/find-experts/edit/state",
  initialState,
  reducers: {
    setActiveStepId: (state, { payload }: PayloadAction<IInitialState["activeStepId"]>) => {
      if (payload < state.activeStepId) {
        const index = state.prevStepIds.indexOf(payload);
        state.prevStepIds = index !== -1 ? state.prevStepIds.slice(0, index) : [];
      }
      state.activeStepId = payload;
    },
    addToPrevStepIds: (state, action: PayloadAction<IInitialState["prevStepIds"]>) => {
      state.prevStepIds.push(...action.payload);
    },
  },
});

export const { setActiveStepId, addToPrevStepIds } = stateSlice.actions;

export default stateSlice.reducer;
