import { ISelectOption } from "models/form";
import { IImage } from "models/shared";

export interface IAvatarUser {
  firstName: string;
  lastName: string;
  profilePicture?: IImage;
}

export const userKeys = [
  "firstName",
  "lastName",
  "profilePicture",
  "phone",
  "locations",
  "suburbs",
  "professional.businessName",
  "professional.job",
  "professional.certificate",
  "buyerSeller.goal",
  "buyerSeller.propertyType",
  "buyerSeller.timeframe",
] as const;

export interface IUser {
  id: string;
  questionnaireComplete: boolean;
  role: { name: "Consumer" | "Professional" | null };
  confirmed: boolean;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture?: IImage;
  phone: string;
  locations: string[];
  suburbs: string[];
  newsletterSubscribed?: boolean;
  invoiceDue?: boolean;
  professional?: {
    businessName: string;
    job: string;
    certificate: string;
  };
  buyerSeller?: {
    goal: string[];
    propertyType: string;
    timeframe: string;
  };
}

export interface IUserTransformed
  extends Omit<IUser, "locations" | "suburbs" | "professional" | "buyerSeller"> {
  locations: ISelectOption[];
  suburbs: ISelectOption[];
  professional?: Pick<NonNullable<IUser["professional"]>, "businessName"> & {
    job: ISelectOption;
    certificate: ISelectOption;
  };
  buyerSeller?: {
    goal: ISelectOption;
    propertyType: ISelectOption;
    timeframe: ISelectOption;
  };
}

export interface IDashboardLayout {
  footer: {
    content: string;
    copyright: string;
  };
}

export interface IHeaderSection {
  title: string;
  content?: string;
  icon: IImage;
}
