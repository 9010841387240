import "styles/globals.css";

import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter";
import { GoogleTagManager } from "@next/third-parties/google";
import { Seo } from "components/shared";
import { Layout } from "components/shared/layout";
import { wrapper } from "lib/redux";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";

function App(props: AppProps) {
  const { Component, pageProps } = props;
  const { store } = wrapper.useWrappedStore(pageProps);

  return (
    <Provider store={store}>
      <Seo />
      <AppCacheProvider {...props}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </AppCacheProvider>
      {process.env.NODE_ENV === "production" && (
        <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID!} />
      )}
    </Provider>
  );
}

export default App;
