import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectAuthProfessionalRegisterState = ({ auth }: RootState) =>
  auth.register.professional.state;
export const selectAuthProfessionalRegisterActiveStep = createSelector(
  (state: RootState) => state.auth.register.professional,
  ({ content: { steps }, state: { activeStepId } }) =>
    steps.find((s) => s.stepId === activeStepId) ?? steps[0],
);
export const selectAuthProfessionalRegisterActiveSteps = createSelector(
  (state: RootState) => state.auth.register.professional,
  ({ content: { steps }, state: { activeStepId, prevStepIds } }) =>
    steps.filter(
      (s) => !prevStepIds.length || prevStepIds.includes(s.stepId) || s.stepId === activeStepId,
    ),
);

interface IInitialState {
  activeStepId: string;
  prevStepIds: string[];
}

const initialState: IInitialState = {
  activeStepId: "",
  prevStepIds: [],
};

const registerStateSlice = createSlice({
  name: "auth/register/professional/state",
  initialState,
  reducers: {
    setActiveStepId: (state, { payload }: PayloadAction<IInitialState["activeStepId"]>) => {
      if (payload < state.activeStepId) {
        const index = state.prevStepIds.indexOf(payload);
        state.prevStepIds = index !== -1 ? state.prevStepIds.slice(0, index) : [];
      }
      state.activeStepId = payload;
    },
    addToPrevStepIds: (state, action: PayloadAction<IInitialState["prevStepIds"]>) => {
      state.prevStepIds.push(...action.payload);
    },
  },
});

export const { setActiveStepId, addToPrevStepIds } = registerStateSlice.actions;

export default registerStateSlice.reducer;
