import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectMainNavState = ({ main }: RootState) => main.layout.nav.state;

interface IInitialState {
  navDrawerOpen: boolean;
}

const initialState: IInitialState = {
  navDrawerOpen: false,
};

const navStateSlice = createSlice({
  name: "main/layout/nav/state",
  initialState,
  reducers: {
    setNavState: (state, action: PayloadAction<Partial<IInitialState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setNavState } = navStateSlice.actions;

export default navStateSlice.reducer;
