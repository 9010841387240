import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IForBuyingContent } from "models/dashboard/consumer/for-buying";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerForBuyingContentReq } from "rest-api/dashboard/consumer/for-buying";

export const getConsumerForBuyingContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/forBuying/content/getConsumerForBuyingContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerForBuyingContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerForBuyingContent = ({ dashboard }: RootState) =>
  dashboard.consumer.forBuying.content;

const initialState: IForBuyingContent = {
  form: { fields: [], submitButtonText: "" },
};

const contentSlice = createSlice({
  name: "dashboard/consumer/forBuying/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerForBuyingContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IForBuyingContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
