import { MutableRefObject, useEffect, useRef, useState } from "react";

const useUpdatedStyles = (): [MutableRefObject<null>, CSSStyleDeclaration] => {
  const ref = useRef(null);
  const [styles, setStyles] = useState<CSSStyleDeclaration>({} as CSSStyleDeclaration);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      const updateStyles = () => {
        const computedStyles = window.getComputedStyle(element);
        setStyles(computedStyles);
      };

      updateStyles();

      const observer = new MutationObserver(updateStyles);

      const config: MutationObserverInit = {
        attributes: true,
        attributeFilter: ["class", "style"],
      };

      observer.observe(element, config);

      return () => observer.disconnect();
    }
  }, []);

  return [ref, styles];
};

export default useUpdatedStyles;
