import { ISelectOption } from "models/form";
import { IUser } from "../shared";

export const referralKeys = ["experts", "expertQuestions"] as const;

export interface IReferral {
  id: string;
  job: string;
  status: "unmatched" | "matched" | "acquired" | "ended";
  expertQuestions: object;
  createdAt: string;
  lead?: {
    status: "new" | "active" | "converted" | "closed";
    professional: Partial<IUser>;
  };
  relatedReferrals?: { id: string }[];
}

export interface IReferralTransformed extends Omit<IReferral, "job"> {
  job: ISelectOption;
}
